<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.littleBorderRad {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

.btn {
	margin-bottom: 8px;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}

.project-picture-preview {
	margin-top: 0.8rem;
	width: 250px;
	height: 250px;
	object-fit: cover;
}

.modal-dialog {
	width: 1000px;
	height: 300px !important;
}

.card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
	padding: 1.25rem;
	flex: 1 1 auto;
}
</style>

<template>
	<errorContainer :error="erreur">
		<div class="animated fadeIn">
			<!-- <pre>{{ project }}</pre> -->
			<b-row class="container-layout px-0">
				<b-col md="12">
					<div class="card card-border-blue-light">
						<b-form @submit.prevent="submitForm">
							<div v-if="errors.length">
								<b>{{ FormMSG(52, 'Please correct the following error(s)') }}:</b>
								<ul>
									<li v-for="(error, i) in errors" :key="i">{{ error }}</li>
								</ul>
							</div>
							<b-row>
								<b-col :sm="!isModal ? 9 : 12">
									<b-form-group :label="FormMSG(125, 'Type')" label-for="projectType" label-cols="3">
										<b-form-select
											id="projectType"
											v-model="project.projectType"
											:disabled="!this.enableProjectTypeChoice || isFilmSingle"
											:options="mapTgsProjectType"
										></b-form-select>
									</b-form-group>
									<b-row v-if="project.projectType == 0 || project.projectType == 3 || project.projectType == 4">
										<b-col lg="12">
											<b-form-group
												:label="FormMSG(504, 'Project reference')"
												label-for="projectReference"
												:label-cols="3"
												:horizontal="true"
											>
												<b-form-input v-model="project.reference" id="projectReference" type="text"></b-form-input>
											</b-form-group>
											<b-form-group :label="FormMSG(1, 'Title')" label-for="titre" :label-cols="3" :horizontal="true">
												<b-form-input v-model="project.titre" id="titre" type="text"></b-form-input>
											</b-form-group>
											<b-row v-if="$screen.width > 567">
												<b-col lg="3" :class="`${$screen.width < 768 ? '' : 'mt-3'}`">
													<p class="fw-700 fs-14 text-color-rhapsody-in-blue">{{ labelLegalEntity }}</p>
												</b-col>
												<b-col lg="9">
													<SupplierSelector
														ref="projectAdmin"
														label=""
														:show-map="false"
														:title="`${
															!isSupplierUpdate ? FormMSG(1205, 'Add entity details') : FormMSG(1206, 'Update entity details')
														}`"
														:placeholder="FormMSG(1132, 'Enter your legal entity name')"
														:addlabel="FormMSG(5446, 'Add')"
														:update-label="FormMSG(13, 'Update')"
														type="projectAdmin"
														:supplier-id="selectedSupplier"
														@change="handleAdminOwnerChange"
														@supplier:unselected="onAdminOwnerUnselected"
													/>
												</b-col>
											</b-row>
											<fieldset v-if="$screen.width <= 567" class="card-inside">
												<legend class="card-inside">{{ labelLegalEntity }}</legend>
												<b-row>
													<b-col>
														<SupplierSelector
															ref="projectAdmin"
															label=""
															:show-map="false"
															:title="`${
																!isSupplierUpdate ? FormMSG(1205, 'Add entity details') : FormMSG(1206, 'Update entity details')
															}`"
															:placeholder="FormMSG(1132, 'Enter your legal entity name')"
															:addlabel="FormMSG(5446, 'Add')"
															:update-label="FormMSG(13, 'Update')"
															type="projectAdmin"
															:supplier-id="selectedSupplier"
															@change="handleAdminOwnerChange"
															@supplier:unselected="onAdminOwnerUnselected"
														/>
													</b-col>
												</b-row>
												<b-row>
													<b-col>
														<b-form-group :label="FormMSG(456, 'VAT for invoices')">
															<b-input-group append="%">
																<b-form-input
																	v-model="project.vatPercent"
																	v-mask="'###'"
																	:placeholder="FormMSG(456, 'VAT for invoices')"
																/>
															</b-input-group>
														</b-form-group>
													</b-col>
												</b-row>
											</fieldset>
											<b-form-group :label="FormMSG(1134, 'Use legal entity data for crew invoicing')" label-cols="3">
												<b-form-checkbox
													v-if="project.projectType !== 3 && project.projectType !== 4"
													size="lg"
													id="useFCI"
													v-model="project.useForCrewInvoice"
													style="margin-top: 4px"
												/>
											</b-form-group>
											<b-form-group
												v-if="project.useForCrewInvoice == false && project.projectType !== 3 && project.projectType !== 4"
												:label="FormMSG(1133, 'Invoice details for crew')"
												label-for="invoiceDetails"
												label-cols="3"
											>
												<b-form-textarea
													id="invoiceDetails"
													:placeholder="this.FormMSG(22, 'Enter invoice details here...')"
													:no-resize="true"
													:state="project.invoiceDetails.length < 4000"
													v-model="project.invoiceDetails"
													rows="6"
												></b-form-textarea>
											</b-form-group>
											<b-form-group
												v-if="project.projectType == 0 || project.projectType == 3 || project.projectType == 4"
												:label="FormMSG(2, 'Production')"
												label-for="production"
												:label-cols="3"
												:horizontal="true"
											>
												<b-form-input v-model="project.production" id="production" type="text"></b-form-input>
											</b-form-group>
											<b-form-group
												v-if="project.projectType == 0"
												:label="FormMSG(20, 'Co-production')"
												label-for="coProducers"
												:label-cols="3"
												:horizontal="true"
											>
												<b-form-input v-model="project.coProducers" id="coProducers" type="text"></b-form-input>
											</b-form-group>
											<b-form-group :label="this.FormMSG(888, 'Production type')" label-for="optionForType" :label-cols="3">
												<b-form-select :options="optionForType" v-model="project.productionType" id="optionForType"></b-form-select>
											</b-form-group>
											<b-row>
												<b-col :cols="6">
													<b-form-group
														:label="this.FormMSG(889, 'Film length/ avg episode length')"
														label-for="optionForType"
														:label-cols="6"
													>
														<b-input-group class="ml-2">
															<b-form-input v-model="project.filmLength" type="number" :placeholder="FormMSG(890, 'minutes')" />
															<b-input-group-append>
																<b-button disabled class="littleBorderRad"> min </b-button>
															</b-input-group-append>
														</b-input-group>
													</b-form-group>
												</b-col>
												<b-col :cols="4">
													<b-form-group
														v-if="project.productionType >= 4 && project.productionType <= 10"
														:label="this.FormMSG(891, 'Total')"
														label-for="optionForType"
														:label-cols="2"
													>
														<b-input-group>
															<b-form-input type="number" v-model="project.numEpisode" />
															<b-input-group-append>
																<b-button disabled class="littleBorderRad"> {{ FormMSG(892, 'episodes') }} </b-button>
															</b-input-group-append>
														</b-input-group>
													</b-form-group>
												</b-col>
											</b-row>
											<b-form-group :label="this.FormMSG(895, 'Shooting format')" label-for="optionForFormat" :label-cols="3">
												<b-form-select :options="optionForFormat" id="optionForFormat" v-model="project.shootingFormat"></b-form-select>
											</b-form-group>
											<b-form-group
												v-if="project.projectType == 0"
												:label="FormMSG(3, 'Director')"
												label-for="realisateur"
												:label-cols="3"
												:horizontal="true"
											>
												<b-form-input v-model="project.realisateur" id="realisateur" type="text"></b-form-input>
											</b-form-group>
											<b-form-group
												v-if="project.projectType == 0"
												:label="FormMSG(21, 'Song and Music')"
												label-for="music"
												:label-cols="3"
												:horizontal="true"
											>
												<b-form-input v-model="project.songMusic" id="music" type="text"></b-form-input>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row v-if="project.projectType === 1">
										<b-col lg="12">
											<b-form-group
												:label="FormMSG(505, 'Project reference')"
												label-for="projectReference"
												:label-cols="3"
												:horizontal="true"
											>
												<b-form-input v-model="project.reference" id="projectReference" type="text"></b-form-input>
											</b-form-group>
											<b-form-group :label="FormMSG(126, 'Office')" label-for="titre" :label-cols="3" :horizontal="true">
												<b-form-input v-model="project.titre" id="titre" type="text"></b-form-input>
											</b-form-group>
											<b-form-group :label="FormMSG(128, 'Legal entity')" label-for="production" :label-cols="3" :horizontal="true">
												<b-form-input v-model="project.production" id="production" type="text"></b-form-input>
											</b-form-group>
											<b-form-group :label="FormMSG(129, 'CEO')" label-for="realisateur" :label-cols="3" :horizontal="true">
												<b-form-input v-model="project.realisateur" id="realisateur" type="text"></b-form-input>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row v-if="project.projectType == 2">
										<b-col lg="12">
											<b-form-group
												:label="FormMSG(506, 'Project reference')"
												label-for="projectReference"
												:label-cols="3"
												:horizontal="true"
											>
												<b-form-input v-model="project.reference" id="projectReference" type="text"></b-form-input>
											</b-form-group>
											<b-form-group :label="FormMSG(127, 'Agency')" label-for="titre" :label-cols="3" :horizontal="true">
												<b-form-input v-model="project.titre" id="titre" type="text"></b-form-input>
											</b-form-group>
											<b-form-group :label="FormMSG(128, 'Legal entity')" label-for="production" :label-cols="3" :horizontal="true">
												<b-form-input v-model="project.production" id="production" type="text"></b-form-input>
											</b-form-group>
											<b-form-group :label="FormMSG(129, 'CEO')" label-for="realisateur" :label-cols="3" :horizontal="true">
												<b-form-input v-model="project.realisateur" id="realisateur" type="text"></b-form-input>
											</b-form-group>
										</b-col>
									</b-row>
									<b-form-group :label="this.FormMSG(80, 'Langue')" label-for="optionsForLanguages" :label-cols="3">
										<b-form-select v-model="project.language" :options="optionsForLanguages" id="optionsForLanguages"></b-form-select>
									</b-form-group>
									<b-form-group :label="this.FormMSG(801, 'Regional Setting')" label-for="regionalSettingOptions" :label-cols="3">
										<b-form-select
											v-model="project.regionalSetting"
											:options="regionalSettingOptions"
											id="regionalSettingOptions"
										></b-form-select>
									</b-form-group>
									<b-form-group :label="FormMSG(4, 'Time zone')" label-for="timeZone" :label-cols="3" :horizontal="true">
										<b-form-input v-model="project.timeZone" id="timeZone" type="text"></b-form-input>
									</b-form-group>
									<b-form-group :label="FormMSG(5, 'Currency')" label-for="currency" label-cols="3">
										<b-form-select
											id="currency"
											v-model="project.currency"
											:disabled="!mapCurrencies.length"
											:options="mapCurrencies"
										></b-form-select>
									</b-form-group>
									<b-form-group :label="FormMSG(6, 'Distance unit')" label-for="distanceUnit" label-cols="3">
										<b-form-select
											id="distanceUnit"
											v-model="project.distanceUnit"
											:disabled="!mapDistacneUnits.length"
											:options="mapDistacneUnits"
										></b-form-select>
									</b-form-group>
									<b-row v-if="!isFilmSingle" class="mb-3">
										<b-col cols="3" class="fs-14 text-color-rhapsody-in-blue">
											{{ FormMSG(238, 'Analytic periods') }}
										</b-col>
										<b-col cols="5">
											<b-form-group v-slot="{ ariaDescribedby }">
												<b-form-radio-group
													id="itterationAnalytic"
													v-model="itterationAnalyticSelected"
													:options="itterationAnalyticOptions"
													:aria-describedby="ariaDescribedby"
													name="radio-inline"
													@change="handleChangeItterationAnalytic"
												/>
											</b-form-group>
										</b-col>
										<b-col v-if="itterationAnalyticSelected !== 1" cols="4">
											<b-form-select id="analyticPeriods" v-model="project.analyticPeriodType" :options="analyticPeriodOptions" />
										</b-col>
									</b-row>
									<b-row>
										<b-col :cols="6">
											<div v-if="project.analyticPeriodType !== 2">
												<b-form-group
													v-if="project.projectType !== 3 && project.projectType !== 4"
													:label="this.FormMSG(48, 'Start date')"
													label-for="startDate"
													label-cols="6"
												>
													<b-form-input
														class="ml-2"
														:value="project.startDate && project.startDate.split('T')[0]"
														@input="updateStartDate"
														type="date"
														id="startDate"
													></b-form-input>
												</b-form-group>
											</div>
										</b-col>
									</b-row>
									<b-row>
										<b-col :cols="6">
											<div>
												<b-form-group
													v-if="
														!isFilmSingle &&
														(project.analyticPeriodType === 1 ||
															project.analyticPeriodType === 2 ||
															project.analyticPeriodType === 4)
													"
													:label="FormMSG(410, 'Shooting start date')"
													label-for="startDateShooting"
													label-cols="6"
												>
													<v-date-picker
														v-model="project.shootingStartDate"
														:available-dates="{
															start: new Date(project.startDate),
															end: new Date(project.endDate)
														}"
														:locale="lang"
														:masks="masks"
													>
														<template v-slot="{ inputValue, togglePopover }">
															<b-input-group class="ml-2">
																<b-form-input :value="inputValue" readonly />
																<b-input-group-append>
																	<b-input-group-text class="cursor-pointer" @click="togglePopover()">
																		<component :is="getLucideIcon('Calendar')" color="#007A77" :size="16" />
																	</b-input-group-text>
																</b-input-group-append>
															</b-input-group>
														</template>
													</v-date-picker>
												</b-form-group>
											</div>
										</b-col>
									</b-row>
									<b-row
										v-if="
											!isFilmSingle &&
											(project.analyticPeriodType === 2 || project.analyticPeriodType === 3 || project.analyticPeriodType === 4)
										"
									>
										<b-col :cols="6">
											<div>
												<b-form-group :label="FormMSG(411, 'Wrap start date')" label-for="startDateWrap" label-cols="6">
													<v-date-picker
														v-model="project.wrapStartDate"
														:available-dates="{
															start: new Date(project.startDate),
															end: new Date(project.endDate)
														}"
														:locale="lang"
														:masks="masks"
													>
														<template v-slot="{ inputValue, togglePopover }">
															<b-input-group class="ml-2">
																<b-form-input :value="inputValue" readonly />
																<b-input-group-append>
																	<b-input-group-text class="cursor-pointer" @click="togglePopover()">
																		<component :is="getLucideIcon('Calendar')" color="#007A77" :size="16" />
																	</b-input-group-text>
																</b-input-group-append>
															</b-input-group>
														</template>
													</v-date-picker>
												</b-form-group>
											</div>
										</b-col>
										<b-col :cols="4">
											<b-form-group :label="this.FormMSG(900, 'Total filming')" :label-cols="6">
												<b-input-group>
													<b-form-input disabled v-model="diffDatesFilm" />
													<b-input-group-append>
														<b-button disabled class="littleBorderRad"> {{ FormMSG(897, 'days') }} </b-button>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col :cols="6">
											<b-form-group
												v-if="project.projectType !== 3 && project.projectType !== 4"
												:label="this.FormMSG(49, 'End date')"
												label-for="endDate"
												label-cols="6"
											>
												<b-form-input
													class="ml-2"
													:value="project.endDate && project.endDate.split('T')[0]"
													@input="updateEndDate"
													type="date"
													id="endDate"
												></b-form-input>
											</b-form-group>
										</b-col>
										<b-col :cols="4">
											<b-form-group :label="this.FormMSG(896, 'Total production')" :label-cols="6">
												<b-input-group>
													<b-form-input disabled v-model="diffDates" />
													<b-input-group-append>
														<b-button disabled class="littleBorderRad"> {{ FormMSG(897, 'days') }} </b-button>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
									</b-row>
									<b-form-group :label="FormMSG(15, 'Carbon calculator')" label-for="carbonCalculator" label-cols="3">
										<b-form-select
											id="carbonCalculator"
											v-model="project.carbonModel"
											:disabled="!mapCarbonModels.length"
											:options="mapCarbonModels"
										/>
									</b-form-group>
									<b-form-group
										v-if="project.projectType !== 3 && project.projectType !== 4 && project.useReporting"
										:label="FormMSG(51, 'Green reporting')"
										label-for="greenReporting"
										label-cols="3"
									>
										<b-form-select
											id="greenReporting"
											v-model="project.greenReporting"
											:disabled="!mapGreenReporting.length"
											:options="mapGreenReporting"
										></b-form-select>
									</b-form-group>
									<b-row>
										<b-col lg="9">
											<b-form-group :label="FormMSG(507, 'Payroll interface')" label-for="payrollInterface" label-cols="4">
												<b-form-select id="payrollInterface" v-model="project.payrollInterface" :options="mapPayrollInterface" />
											</b-form-group>
										</b-col>
										<b-col lg="3" class="select_items_filter_wrapper" id="payrollCode" v-if="project.payrollInterface > 0">
											<payroll-code style-type="button" />
										</b-col>
										<b-tooltip target="payrollCode" triggers="hover">{{ FormMSG(562, 'Manage payroll codes...') }}</b-tooltip>
									</b-row>
									<b-row v-if="$screen.width > 567">
										<b-col cols="9" offset="3">
											<b-form-group :label="FormMSG(456, 'VAT for invoices')" label-cols="3" horizontal>
												<b-input-group append="%">
													<b-form-input v-model="project.vatPercent" v-mask="'###'" :placeholder="FormMSG(456, 'VAT for invoices')" />
													<!-- @input="handleInputVatPercent" -->
												</b-input-group>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row v-if="displayLargeAccount">
										<b-col lg="12">
											<b-form-group
												class="form-row align-items-center"
												:label="FormMSG(1234, 'Large account')"
												label-for="largeAccount"
												:label-cols="3"
											>
												<b-form-checkbox
													size="lg"
													id="largeAccount"
													v-model="project.largeAccount"
													:disabled="this.disableLargeAccount"
												></b-form-checkbox>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row v-if="displayLargeAccount && project.largeAccount">
										<b-col lg="12">
											<b-form-group
												class="form-row align-items-center"
												:label="FormMSG(1135, 'Payment status')"
												label-for="largeAccountPaid"
												:label-cols="3"
											>
												<b-form-checkbox
													size="lg"
													id="largeAccountPaid"
													v-model="project.largeAccountPaid"
													:disabled="this.disableLargeAccount"
												></b-form-checkbox>
											</b-form-group>
										</b-col>
									</b-row>
									<div v-if="project.projectType !== 3 && project.projectType !== 4">
										<b-row>
											<b-col lg="12">
												<b-form-group
													:label="FormMSG(65, 'Custom user title')"
													label-for="userTitleSideNavbar"
													:label-cols="3"
													:horizontal="true"
												>
													<b-form-input
														v-model="project.userName"
														:placeholder="FormMSG(69, 'e.g: User or Crew Member or Employee or ...')"
														type="text"
													></b-form-input>
												</b-form-group>
											</b-col>
										</b-row>
										<b-row>
											<b-col lg="12">
												<b-form-group
													:label="FormMSG(70, 'Custom hod title')"
													label-for="hodTitleSideNavbar"
													:label-cols="3"
													:horizontal="true"
												>
													<b-form-input
														v-model="project.hodName"
														:placeholder="FormMSG(71, 'e.g: Head of Department or ...')"
														type="text"
													></b-form-input>
												</b-form-group>
											</b-col>
										</b-row>
										<b-row>
											<b-col lg="12">
												<b-form-group
													:label="FormMSG(72, 'Custom production title')"
													label-for="productionTitleSideNavbar"
													:label-cols="3"
													:horizontal="true"
												>
													<b-form-input
														v-model="project.managerName"
														:placeholder="FormMSG(73, 'e.g: Production or Management or ...')"
														type="text"
													></b-form-input>
												</b-form-group>
											</b-col>
										</b-row>
										<b-row v-if="project.useExpense">
											<b-col lg="12">
												<b-form-group
													:label="FormMSG(814, 'Max items per expense sheet')"
													label-for="productionTitleSideNavbar"
													:label-cols="3"
													:horizontal="true"
												>
													<b-form-input v-model="project.maxExpenseItemsPerSheet" type="text"></b-form-input>
												</b-form-group>
											</b-col>
										</b-row>
									</div>

									<div class="mb-2" v-if="project.projectType !== 3 && project.projectType !== 4 && project.greenReporting === 3">
										<fieldset class="my-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
											<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
												{{ this.FormMSG(689, 'Options for carbon clap') }}
											</legend>
											<div>
												<carbon-clap-option :project-data="project" @carbon-clap-otpion:change="handleCarbonClapOptionChange" />
											</div>
										</fieldset>
									</div>
									<div class="mb-2" v-if="project.projectType !== 3 && project.projectType !== 4">
										<fieldset class="my-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
											<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
												{{ this.FormMSG(560, 'Options for budget') }}
											</legend>
											<b-row>
												<b-col>
													<b-form-checkbox size="lg" id="lockedBudget" v-model="project.budgetLocked" class="pj-cb pb-1">{{
														FormMSG(561, 'Locked budget')
													}}</b-form-checkbox>
												</b-col>
											</b-row>
										</fieldset>
									</div>
									<div v-if="project.projectType !== 3 && project.projectType !== 4">
										<fieldset class="my-0 py-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
											<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
												{{ this.FormMSG(4010, 'Features') }}
											</legend>
											<b-row>
												<b-col sm="12" md="12" lg="3" xl="3" class="h-100">
													<div class="card card-border-blue-light">
														<b-row>
															<b-col>
																<b-form-checkbox
																	size="lg"
																	id="useEntr"
																	:disabled="!isDev"
																	v-model="project.useEntryForCrew"
																	class="pj-cb pb-1"
																	@change="handleControlEntryForCrew($event, 0)"
																>
																	{{ FormMSG(800, 'Entry for crew') }}
																</b-form-checkbox>
																<b-row>
																	<b-col offset-sm="1">
																		<b-form-checkbox
																			size="lg"
																			:disabled="!isDev"
																			id="useEntrTimes"
																			v-model="project.useEntryForCrewTimesheet"
																			class="pj-cb pb-1"
																			@change="handleControlEntryForCrew($event, 1)"
																		>
																			<div>{{ FormMSG(300, 'Timesheets') }}</div>
																		</b-form-checkbox>
																	</b-col>
																</b-row>
																<b-row>
																	<b-col offset-sm="1">
																		<b-form-checkbox
																			size="lg"
																			:disabled="!isDev"
																			id="useEntrExp"
																			v-model="project.useEntryForCrewExpenses"
																			class="pj-cb pb-1"
																			@change="handleControlEntryForCrew($event, 2)"
																		>
																			<div>{{ FormMSG(400, 'Expenses') }}</div>
																		</b-form-checkbox>
																	</b-col>
																</b-row>
																<b-row>
																	<b-col offset-sm="1">
																		<b-form-checkbox
																			size="lg"
																			:disabled="!isDev"
																			id="useEntrPo"
																			v-model="project.useEntryForCrewPo"
																			class="pj-cb pb-1"
																			@change="handleControlEntryForCrew($event, 3)"
																		>
																			<div>{{ FormMSG(402, 'Purchase Orders') }}</div>
																		</b-form-checkbox>
																	</b-col>
																</b-row>
															</b-col>
														</b-row>
													</div>
												</b-col>
												<b-col sm="12" md="12" lg="3" xl="3" class="h-100">
													<div class="card card-border-blue-light">
														<b-row>
															<b-col>
																<b-form-checkbox
																	size="lg"
																	id="useEntraa"
																	:disabled="!isDev"
																	v-model="project.useProduction"
																	@change="handleUseProd($event, 0)"
																	class="pj-cb pb-1"
																>
																	{{ FormMSG(8005, 'Production') }}
																</b-form-checkbox>
																<b-row>
																	<b-col offset-sm="1">
																		<b-form-checkbox
																			size="lg"
																			id="useEntrTimess"
																			:disabled="!isDev"
																			v-model="project.useCrewSetup"
																			@change="handleUseProd($event, 1)"
																			class="pj-cb pb-1"
																		>
																			<div>{{ FormMSG(3050, 'Crew setup') }}</div>
																		</b-form-checkbox>
																	</b-col>
																</b-row>
																<b-row>
																	<b-col offset-sm="1">
																		<b-form-checkbox
																			size="lg"
																			id="useEntrExpens"
																			:disabled="!isDev"
																			v-model="project.useContacts"
																			@change="handleUseProd($event, 1)"
																			class="pj-cb pb-1"
																		>
																			<div>{{ FormMSG(40014, 'Contacts') }}</div>
																		</b-form-checkbox>
																	</b-col>
																</b-row>
																<b-row>
																	<b-col offset-sm="1">
																		<b-form-checkbox
																			size="lg"
																			id="useDoc"
																			:disabled="!isDev"
																			v-model="project.useDocument"
																			@change="handleUseProd($event, 1)"
																			class="pj-cb pb-1"
																			>{{ FormMSG(4050, 'Documents & Packages') }}</b-form-checkbox
																		>
																	</b-col>
																</b-row>
																<b-row>
																	<b-col offset-sm="1">
																		<b-form-checkbox
																			size="lg"
																			id="useCallSheet"
																			:disabled="!isDev"
																			v-model="project.useCallSheet"
																			@change="handleUseProd($event, 1)"
																			class="pj-cb pb-1"
																			>{{ FormMSG(4055, 'Call sheet') }}</b-form-checkbox
																		>
																	</b-col>
																</b-row>
																<b-row>
																	<b-col offset-sm="1">
																		<b-form-checkbox
																			size="lg"
																			:disabled="!isDev"
																			id="useAcc"
																			v-model="project.useAccommodation"
																			@change="handleUseProd($event, 1)"
																			class="pj-cb pb-1"
																			>{{ FormMSG(403, 'Accommodations') }}</b-form-checkbox
																		>
																		<b-form-checkbox
																			size="lg"
																			id="useLocations"
																			v-model="project.useLocations"
																			:disabled="!isDev"
																			@change="handleUseProd($event, 1)"
																			class="pj-cb pb-1"
																			>{{ FormMSG(4040, 'Locations') }}</b-form-checkbox
																		>
																		<b-form-checkbox
																			size="lg"
																			id="useTra"
																			v-model="project.useTransport"
																			:disabled="!isDev"
																			@change="handleUseProd($event, 1)"
																			class="pj-cb pb-1"
																			>{{ FormMSG(404, 'Transports') }}</b-form-checkbox
																		>
																		<b-form-checkbox
																			size="lg"
																			id="useTran"
																			v-model="project.useExport"
																			:disabled="!isDev"
																			@change="handleUseProd($event, 1)"
																			class="pj-cb pb-1"
																			>{{ FormMSG(4041, 'Export') }}</b-form-checkbox
																		>
																		<b-form-checkbox
																			size="lg"
																			id="useTrnsa"
																			v-model="project.useImport"
																			:disabled="!isDev"
																			@change="handleUseProd($event, 1)"
																			class="pj-cb pb-1"
																			>{{ FormMSG(4042, 'Import') }}</b-form-checkbox
																		>
																	</b-col>
																</b-row>
															</b-col>
														</b-row>
													</div>
												</b-col>
												<b-col sm="12" md="12" lg="3" xl="3" class="h-100">
													<div class="card card-border-blue-light">
														<b-row>
															<b-col>
																<b-form-checkbox
																	size="lg"
																	id="useFinance"
																	@change="handleFinance($event, 0)"
																	v-model="project.useFinance"
																	:disabled="!isDev"
																	class="pj-cb pb-1"
																>
																	{{ FormMSG(5800, 'Finance') }}
																</b-form-checkbox>
																<b-row>
																	<b-col offset-sm="1">
																		<b-form-checkbox
																			size="lg"
																			id="useGS"
																			@change="handleFinance($event, 1)"
																			v-model="project.useGreenSupplier"
																			:disabled="!isDev"
																			class="pj-cb pb-1"
																			>{{ FormMSG(407, 'Green Suppliers') }}</b-form-checkbox
																		>
																		<b-form-checkbox
																			size="lg"
																			id="usePO"
																			@change="handleFinance($event, 1)"
																			v-model="project.usePurchaseOrder"
																			:disabled="!isDev"
																			class="pj-cb pb-1"
																			>{{ FormMSG(402, 'Purchase Orders') }}</b-form-checkbox
																		>
																		<b-form-checkbox
																			size="lg"
																			id="useExp"
																			@change="handleFinance($event, 2)"
																			v-model="project.useExpense"
																			:disabled="!isDev"
																			class="pj-cb pb-1"
																		>
																			{{ FormMSG(400, 'Expenses') }}
																		</b-form-checkbox>
																		<b-row>
																			<b-col>
																				<b-row>
																					<b-col offset="1">
																						<b-form-checkbox
																							size="lg"
																							id="useExpTravel"
																							v-model="project.useExpenseTravel"
																							:disabled="!isDev"
																							class="pj-cb pb-1"
																							@change="handleControlExpense($event, 1)"
																						>
																							<div>{{ FormMSG(501, 'Travel') }}</div>
																						</b-form-checkbox>
																					</b-col>
																				</b-row>
																				<b-row>
																					<b-col offset="1">
																						<b-form-checkbox
																							size="lg"
																							:disabled="!isDev"
																							id="useExpTicket"
																							v-model="project.useExpenseTicket"
																							class="pj-cb pb-1"
																							@change="handleControlExpense($event, 2)"
																						>
																							<div>{{ FormMSG(502, 'Ticket') }}</div>
																						</b-form-checkbox>
																					</b-col>
																				</b-row>
																				<b-row>
																					<b-col offset="1">
																						<b-form-checkbox
																							size="lg"
																							:disabled="!isDev"
																							id="useExpInvoice"
																							v-model="project.useExpenseInvoice"
																							class="pj-cb pb-1"
																							@change="handleControlExpense($event, 3)"
																						>
																							<div>{{ FormMSG(503, 'Invoice') }}</div>
																						</b-form-checkbox>
																					</b-col>
																				</b-row>
																			</b-col>
																		</b-row>
																		<b-form-checkbox
																			size="lg"
																			id="useTs"
																			@change="handleFinance($event, 1)"
																			v-model="project.useTimesheet"
																			:disabled="!isDev"
																			class="pj-cb"
																			>{{ FormMSG(300, 'Timesheets:') }}</b-form-checkbox
																		>
																		<b-form-checkbox
																			size="lg"
																			id="useTsPl"
																			@change="handleFinance($event, 1)"
																			v-model="project.useTimesheetPlanning"
																			:disabled="!isDev"
																			class="pj-cb"
																			>{{ FormMSG(30074, 'Timesheets planning') }}</b-form-checkbox
																		>
																		<b-form-checkbox
																			size="lg"
																			id="useBud"
																			@change="handleFinance($event, 1)"
																			v-model="project.useBudget"
																			:disabled="!isDev"
																			class="pj-cb"
																			>{{ FormMSG(409, 'Budget') }}</b-form-checkbox
																		>
																	</b-col>
																</b-row>
															</b-col>
														</b-row>
													</div>
												</b-col>
												<b-col sm="12" md="12" lg="3" xl="3" class="h-100">
													<div class="card card-border-blue-light">
														<b-row>
															<b-col>
																<b-form-checkbox
																	size="lg"
																	id="useGreen"
																	@change="handleGreen($event, 0)"
																	v-model="project.useGreen"
																	:disabled="!isDev"
																	class="pj-cb pb-1"
																>
																	{{ FormMSG(80840, 'Green') }}
																</b-form-checkbox>
																<b-row>
																	<b-col offset-sm="1">
																		<b-form-checkbox
																			size="lg"
																			id="useCarbTempl"
																			@change="handleGreen($event, 1)"
																			:disabled="!isDev"
																			v-model="project.useCarbonTemplate"
																			class="pj-cb pb-1"
																		>
																			<div>{{ FormMSG(30710, 'Carbon template') }}</div>
																		</b-form-checkbox>
																	</b-col>
																</b-row>
																<b-row>
																	<b-col offset-sm="1">
																		<b-form-checkbox
																			size="lg"
																			id="useGreenTable"
																			@change="handleGreen($event, 1)"
																			:disabled="!isDev"
																			v-model="project.useGreenTable"
																			class="pj-cb pb-1"
																		>
																			<div>{{ FormMSG(40740, 'Green table') }}</div>
																		</b-form-checkbox>
																	</b-col>
																</b-row>
																<b-row>
																	<b-col offset-sm="1">
																		<b-form-checkbox
																			size="lg"
																			id="useReport"
																			@change="handleGreen($event, 1)"
																			:disabled="!isDev"
																			v-model="project.useReporting"
																			class="pj-cb pb-1"
																		>
																			<div>{{ FormMSG(40982, 'Reporting') }}</div>
																		</b-form-checkbox>
																		<b-form-checkbox
																			size="lg"
																			id="useCarbRemove"
																			@change="handleGreen($event, 1)"
																			:disabled="!isDev"
																			v-model="project.useCarbonRemoval"
																			class="pj-cb pb-1"
																		>
																			<div>{{ FormMSG(40983, 'Carbon removal') }}</div>
																		</b-form-checkbox>
																		<b-form-checkbox
																			size="lg"
																			id="useWaste"
																			@change="handleGreen($event, 1)"
																			:disabled="!isDev"
																			v-model="project.useWaste"
																			class="pj-cb pb-1"
																		>
																			<div>{{ FormMSG(40984, 'Waste') }}</div>
																		</b-form-checkbox>
																		<b-form-checkbox
																			size="lg"
																			id="useTOD"
																			@change="handleGreen($event, 1)"
																			:disabled="!isDev"
																			v-model="project.useTipOfDay"
																			class="pj-cb pb-1"
																			>{{ FormMSG(406, 'Tip Of Day') }}</b-form-checkbox
																		>
																	</b-col>
																</b-row>
															</b-col>
														</b-row>
													</div>
												</b-col>
												<!-- <b-col sm="12" md="12" lg="6" xl="6">
													<div class="card card-border-blue-light">
														<b-row>
															<b-col>
																<b-row>
																	<b-col offset-sm="1">
																		<b-form-checkbox
																			size="lg"
																			id="useExpTravel"
																			v-model="project.useExpenseTravel"
																			class="pj-cb pb-1"
																			@change="handleControlExpense($event, 1)"
																		>
																			<div>{{ FormMSG(501, 'Travel') }}</div>
																		</b-form-checkbox>
																	</b-col>
																</b-row>
																<b-row>
																	<b-col offset-sm="1">
																		<b-form-checkbox
																			size="lg"
																			id="useExpTicket"
																			v-model="project.useExpenseTicket"
																			class="pj-cb pb-1"
																			@change="handleControlExpense($event, 2)"
																		>
																			<div>{{ FormMSG(502, 'Ticket') }}</div>
																		</b-form-checkbox>
																	</b-col>
																</b-row>
																<b-row>
																	<b-col offset-sm="1">
																		<b-form-checkbox
																			size="lg"
																			id="useExpInvoice"
																			v-model="project.useExpenseInvoice"
																			class="pj-cb pb-1"
																			@change="handleControlExpense($event, 3)"
																		>
																			<div>{{ FormMSG(503, 'Invoice') }}</div>
																		</b-form-checkbox>
																	</b-col>
																</b-row>
															</b-col>
														</b-row>
													</div>
												</b-col> -->
												<!-- <b-col sm="12" md="12" lg="6" xl="6">
													<div class="card card-border-blue-light">
														<b-form-checkbox size="lg" id="useTsk" v-model="project.useTask" class="pj-cb pb-1">{{
															FormMSG(408, 'Tasks')
														}}</b-form-checkbox>
													</div>
												</b-col> -->
											</b-row>
										</fieldset>
									</div>
									<div v-if="project.projectType !== 3 && project.projectType !== 4" class="mt-2">
										<fieldset class="my-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
											<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
												{{ this.FormMSG(652, 'Advanced options') }}
											</legend>
											<b-row>
												<b-col>
													<b-form-checkbox v-model="allowEntryOutsideContract" size="lg" id="allow_outside_c" class="pj-cb pb-1">
														{{ FormMSG(653, 'Allow entry outside contract') }}
													</b-form-checkbox>
													<b-row>
														<b-col class="ml-5">
															<b-form-checkbox
																size="lg"
																id="allow_outside_c_timesheets"
																v-model="project.allowTSEntryOutsideContract"
																class="pj-cb pb-1"
															>
																<div>{{ FormMSG(654, 'Timesheets') }}</div>
															</b-form-checkbox>
														</b-col>
													</b-row>
													<b-row>
														<b-col class="ml-5">
															<b-form-checkbox
																size="lg"
																id="allow_outside_c_expenses"
																v-model="project.allowExpenseEntryOutsideContract"
																class="pj-cb pb-1"
															>
																<div>{{ FormMSG(400, 'Expenses') }}</div>
															</b-form-checkbox>
														</b-col>
													</b-row>

													<b-row>
														<b-col class="ml-5">
															<b-form-checkbox
																v-model="project.allowPOEntryOutsideContract"
																size="lg"
																id="allow_outside_c_po"
																class="pj-cb pb-1"
															>
																<div>{{ FormMSG(402, 'Purchase Orders') }}</div>
															</b-form-checkbox>
														</b-col>
													</b-row>
												</b-col>
											</b-row>
											<b-row>
												<b-col>
													<b-form-checkbox size="lg" id="use_klippa_ocr" v-model="project.useKlippaOcr" class="pj-cb pb-1">
														<div>{{ FormMSG(655, 'OCR') }}</div>
													</b-form-checkbox>
												</b-col>
											</b-row>
											<b-row>
												<b-col>
													<b-form-checkbox size="lg" id="hide_salary_info" v-model="project.hideSalaryInfo" class="pj-cb pb-1">
														<div>{{ FormMSG(6505, 'Hide salary information') }}</div>
													</b-form-checkbox>
												</b-col>
											</b-row>
										</fieldset>
									</div>
									<div v-if="project.useTimesheet" class="mt-2">
										<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
											<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
												{{ this.FormMSG(24, 'Time management') }}
											</legend>
											<b-row>
												<b-col cols="12">
													<b-row>
														<b-col sm="12" md="12" lg="4" xl="4">
															<div class="card card-border-blue-light">
																<b-form-checkbox
																	size="lg"
																	id="ovt"
																	v-model="project.useOvertime"
																	class="pj-cb pb-1"
																	@change="handleControlOvertime($event, 'daily', 1)"
																>
																	{{ FormMSG(25, 'Compute daily overtime:') }}
																</b-form-checkbox>
																<b-form-checkbox
																	size="lg"
																	id="ovt1"
																	v-model="project.useOvt1"
																	class="pj-cb pb-1"
																	@change="handleControlOvertime($event, 'daily', 2)"
																>
																	{{ FormMSG(325, 'Compute daily overtime 1') }}
																</b-form-checkbox>
																<b-form-checkbox
																	size="lg"
																	id="ovt2"
																	v-model="project.useOvt2"
																	class="pj-cb pb-1"
																	@change="handleControlOvertime($event, 'daily', 3)"
																>
																	{{ FormMSG(326, 'Compute daily overtime 2:') }}
																</b-form-checkbox>
																<b-form-checkbox
																	size="lg"
																	id="useWeeklyOvt"
																	v-model="project.useWeeklyOvt"
																	class="pj-cb pb-1"
																	@change="handleControlOvertime($event, 'weekly', 1)"
																>
																	{{ FormMSG(157, 'Compute weekly overtime') }}
																</b-form-checkbox>
																<b-form-checkbox
																	size="lg"
																	id="useWeeklyOvt1"
																	v-model="project.useWeeklyOvt1"
																	class="pj-cb pb-1"
																	@change="handleControlOvertime($event, 'weekly', 2)"
																>
																	{{ FormMSG(158, 'Compute weekly overtime 2') }}
																</b-form-checkbox>
																<b-form-checkbox
																	size="lg"
																	id="useWeeklyOvt2"
																	v-model="project.useWeeklyOvt2"
																	class="pj-cb pb-1"
																	@change="handleControlOvertime($event, 'weekly', 3)"
																>
																	{{ FormMSG(159, 'Compute weekly overtime 3') }}
																</b-form-checkbox>
															</div>
														</b-col>
														<b-col sm="12" md="12" lg="4" xl="4">
															<div class="card card-border-blue-light">
																<b-form-checkbox size="lg" id="cnh" v-model="project.useNightHours" class="pj-cb pb-1">
																	{{ FormMSG(26, 'Compute night hours:') }}
																</b-form-checkbox>
																<b-form-checkbox size="lg" id="tbtwwd" v-model="project.useTooEarlyHours" class="pj-cb pb-1">
																	{{ FormMSG(27, 'Use rest time') }}
																</b-form-checkbox>
																<b-form-checkbox size="lg" id="chtwt" v-model="project.useHomeToWorkTime" class="pj-cb pb-1">
																	{{ FormMSG(336, 'Compute home to work time') }}
																</b-form-checkbox>
																<b-form-checkbox size="lg" id="ult" v-model="project.useLunchTime" class="pj-cb pb-1">
																	{{ FormMSG(29, 'Use lunch time:') }}
																</b-form-checkbox>
																<b-form-checkbox
																	v-if="!isFilmSingle"
																	size="lg"
																	id="usd"
																	v-model="project.useStageDay"
																	class="pj-cb pb-1"
																>
																	{{ FormMSG(30, 'Use stage day:') }}
																</b-form-checkbox>
																<b-form-checkbox size="lg" id="sdsr" v-model="project.useSixthDay" class="pj-cb pb-1">
																	{{ FormMSG(28, 'Use sixth day special rate:') }}
																</b-form-checkbox>
																<b-form-checkbox
																	size="lg"
																	id="useSeventhDay"
																	v-model="project.useSeventhDay"
																	class="pj-cb pb-1"
																>
																	{{ FormMSG(161, 'Use 7th special rate') }}
																</b-form-checkbox>
																<b-form-checkbox
																	size="lg"
																	id="useSundayRate"
																	v-model="project.useSundayRate"
																	class="pj-cb pb-1"
																>
																	{{ FormMSG(160, 'Use sunday special rate') }}
																</b-form-checkbox>
															</div>
														</b-col>
														<b-col sm="12" md="12" lg="4" xl="4">
															<div class="card card-border-blue-light">
																<b-form-checkbox size="lg" id="ulpd" v-model="project.useLunchPerDiem" class="pj-cb pb-1">
																	{{ FormMSG(139, 'Use lunch per diem:') }}
																</b-form-checkbox>
																<b-form-checkbox
																	size="lg"
																	id="dinnerPerDiem"
																	v-model="project.useDinnerPerDiem"
																	class="pj-cb pb-1"
																>
																	{{ FormMSG(301, 'Use dinner per diem') }}
																</b-form-checkbox>
																<b-form-checkbox size="lg" id="uhpd" v-model="project.useHotelPerDiem" class="pj-cb pb-1">
																	{{ FormMSG(130, 'Use hotel per diem:') }}
																</b-form-checkbox>
																<b-form-checkbox
																	size="lg"
																	id="abroadPerDiem"
																	v-model="project.useAbroadPerDiem"
																	class="pj-cb pb-1"
																>
																	{{ FormMSG(302, 'Use abroad per diem') }}
																</b-form-checkbox>
																<b-form-checkbox
																	v-if="!isFilmSingle"
																	size="lg"
																	id="ubct"
																	v-model="project.useBeforeCallTime"
																	class="pj-cb pb-1"
																>
																	{{ FormMSG(201, 'Use before call time') }}
																</b-form-checkbox>
															</div>
														</b-col>
													</b-row>
													<b-row>
														<b-col sm="12" md="4" lg="4" offset-md="8" offset-lg="8">
															<b-button block variant="outline-primary" @click="showHolidaysModal = true">
																{{ FormMSG(245, 'Manage public holidays') }}
															</b-button>
														</b-col>
													</b-row>
												</b-col>
											</b-row>
											<b-row v-if="isFilmSingle">
												<b-col cols="12" :class="`${$screen.width <= 576 ? 'pt-3' : ''}`" class="text-center">
													<b-button
														class="btn"
														:class="`${$screen.width <= 576 ? 'w-100' : 'w-50'}`"
														@click="loadCcnForSolo"
														variant="primary"
													>
														{{ FormMSG(251, 'Load French CCN cinema salaries and functions') }}
													</b-button>
												</b-col>
											</b-row>
										</fieldset>
									</div>
									<div v-if="project.projectType === 0 || project.projectType === 1 || project.projectType === 4">
										<fieldset
											class="my-2 py-0"
											:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
											v-if="project.projectType === 1"
										>
											<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
												{{ FormMSG(31, 'Offer Parameter') }}
											</legend>
											<div class="pb-3">
												<div class="alert alert-warning" role="alert">
													<div class="d-flex align-items-center" style="color: rgba(0, 0, 0, 0.6)">
														<div class="pr-3" style="font-size: 1.5rem">
															<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
														</div>
														<div class="mt-0" :style="`font-size: ${$screen.width <= 576 ? '.9' : '1'}rem;`">
															{{
																FormMSG(
																	32,
																	`You should specify your own offer number format, otherwise the default format will be chosen automatically. e.g: "1"`
																)
															}}
														</div>
													</div>
												</div>
												<div class="d-flex flex-row">
													<b-form-radio-group
														v-model="selectedRadioOffer"
														:options="radioOfferOptions"
														class="lh-22"
														@change="handleChangeOfferParameter"
													></b-form-radio-group>
												</div>
												<div class="d-block mt-2">
													<fieldset class="my-0 pb-3 w-100" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
														<fieldset
															class="my-0 pb-3 w-100"
															:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
														>
															<legend class="scheduler-border">{{ FormMSG(36, 'Preview') }}</legend>
															<div class="d-flex flex-row text-muted">
																{{ FormMSG(37, 'e.g:') }} <span class="pl-1 last-number-format">{{ renderOfferFormat }}</span>
															</div>
														</fieldset>
														<div v-if="!selectedRadioOffer">
															<fieldset
																class="my-0 pb-2 w-100"
																:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
															>
																<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
																	{{ FormMSG(61, 'Define length') }}
																</legend>
																<div class="d-flex flex-row">
																	<div class="pr-2" style="margin-top: 2px">1</div>
																	<div class="w-100">
																		<b-form-input
																			v-model="offerInitialLength"
																			type="range"
																			min="1"
																			max="10"
																			step="1"
																			@change="handleChangeOfferInitialLength"
																		></b-form-input>
																	</div>
																	<div class="pl-2" style="margin-top: 2px">10</div>
																</div>
																<div class="justify-content-center">
																	<div class="w-100 text-center text-success">{{ offerInitialLength }}</div>
																</div>
															</fieldset>
															<fieldset
																class="my-0 pb-3 w-100"
																:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
															>
																<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
																	{{ FormMSG(35, 'Choose year') }}
																</legend>
																<div class="d-flex flex-row">
																	<b-form-radio-group
																		v-model="selectedRadioOfferYear"
																		:options="radioOfferYearOptions"
																		class="lh-22"
																		@change="handleChangeOfferYear"
																	></b-form-radio-group>
																</div>
															</fieldset>
															<fieldset
																class="my-0 pb-0 w-100"
																:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
															>
																<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
																	{{ FormMSG(33, 'Define prefix') }}
																</legend>
																<b-row>
																	<b-col sm="6">
																		<b-form-group label-for="offerInitialText">
																			<b-form-input
																				v-model.trim="offerInitialText"
																				type="text"
																				:placeholder="FormMSG(60, 'e.g: TGS ...')"
																				@input="handleChangeOfferInitialText"
																			></b-form-input>
																		</b-form-group>
																	</b-col>
																</b-row>
															</fieldset>
														</div>
														<fieldset
															class="my-0 pb-0 w-100"
															:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
														>
															<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
																{{ FormMSG(68, 'Enter your last number') }}
															</legend>
															<b-row>
																<b-col sm="6">
																	<b-form-group label-for="offerLastId">
																		<b-form-input
																			v-model="project.lastOfferNumber"
																			type="number"
																			step="1"
																			min="0"
																			max="100000"
																			:placeholder="'e.g: 101'"
																			:state="lastNumberValidation(project.lastOfferNumber)"
																			@input="handleChangeOfferLastNumber"
																		></b-form-input>
																		<b-form-invalid-feedback :state="lastNumberValidation(project.lastOfferNumber)">
																			{{ FormMSG(34, 'Length must be 0-100000') }}
																		</b-form-invalid-feedback>
																	</b-form-group>
																</b-col>
															</b-row>
														</fieldset>
													</fieldset>
												</div>
											</div>
										</fieldset>
										<fieldset
											class="my-2 py-0"
											:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
											v-if="project.projectType === 1"
										>
											<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
												{{ FormMSG(38, 'Order Parameter') }}
											</legend>
											<div class="pb-3">
												<div class="alert alert-warning" role="alert">
													<div class="d-flex align-items-center" style="color: rgba(0, 0, 0, 0.6)">
														<div class="pr-3" style="font-size: 1.5rem">
															<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
														</div>
														<div class="mt-0" :style="`font-size: ${$screen.width <= 576 ? '.9' : '1'}rem;`">
															{{
																FormMSG(
																	39,
																	`You should specify your own order number format, otherwise the default format will be chosen automatically. e.g: "1"`
																)
															}}
														</div>
													</div>
												</div>
												<div class="d-flex flex-row">
													<b-form-radio-group
														v-model="selectedRadioOrder"
														:options="radioOrderOptions"
														class="lh-22"
														@change="handleChangeOrderParameter"
													></b-form-radio-group>
												</div>
												<div class="d-block mt-2">
													<fieldset class="my-0 pb-3 w-100" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
														<fieldset
															class="my-0 pb-3 w-100"
															:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
														>
															<legend class="scheduler-border">{{ FormMSG(36, 'Preview') }}</legend>
															<div class="d-flex flex-row text-muted">
																{{ FormMSG(37, 'e.g:') }} <span class="pl-1 last-number-format">{{ renderOrderFormat }}</span>
															</div>
														</fieldset>
														<div v-if="!selectedRadioOrder">
															<fieldset
																class="my-0 pb-2 w-100"
																:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
															>
																<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
																	{{ FormMSG(61, 'Define length') }}
																</legend>
																<div class="d-flex flex-row">
																	<div class="pr-2" style="margin-top: 2px">1</div>
																	<div class="w-100">
																		<b-form-input
																			v-model="orderInitialLength"
																			type="range"
																			min="1"
																			max="10"
																			step="1"
																			@change="handleChangeOrderInitialLength"
																		></b-form-input>
																	</div>
																	<div class="pl-2" style="margin-top: 2px">10</div>
																</div>
																<div class="justify-content-center">
																	<div class="w-100 text-center text-success">{{ orderInitialLength }}</div>
																</div>
															</fieldset>
															<fieldset
																class="my-0 pb-3 w-100"
																:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
															>
																<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
																	{{ FormMSG(35, 'Choose year') }}
																</legend>
																<div class="d-flex flex-row">
																	<b-form-radio-group
																		v-model="selectedRadioOrderYear"
																		:options="radioOrderYearOptions"
																		class="lh-22"
																		@change="handleChangeOrderYear"
																	></b-form-radio-group>
																</div>
															</fieldset>
															<fieldset
																class="my-0 pb-0 w-100"
																:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
															>
																<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
																	{{ FormMSG(33, 'Define prefix') }}
																</legend>
																<b-row>
																	<b-col sm="6">
																		<b-form-group label-for="orderInitialText">
																			<b-form-input
																				v-model.trim="orderInitialText"
																				type="text"
																				:placeholder="FormMSG(60, 'e.g: TGS ...')"
																				@input="handleChangeOrderInitialText"
																			></b-form-input>
																		</b-form-group>
																	</b-col>
																</b-row>
															</fieldset>
														</div>
														<fieldset
															class="my-0 pb-0 w-100"
															:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
														>
															<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
																{{ FormMSG(68, 'Enter your last number') }}
															</legend>
															<b-row>
																<b-col sm="6">
																	<b-form-group label-for="orderLastId">
																		<b-form-input
																			v-model="project.lastOrderNumber"
																			type="number"
																			step="1"
																			min="0"
																			max="100000"
																			:placeholder="'e.g: 101'"
																			:state="lastNumberValidation(project.lastOrderNumber)"
																			@input="handleChangeOrderLastNumber"
																		></b-form-input>
																		<b-form-invalid-feedback :state="lastNumberValidation(project.lastOrderNumber)">
																			{{ FormMSG(34, 'Length must be 0-100000') }}
																		</b-form-invalid-feedback>
																	</b-form-group>
																</b-col>
															</b-row>
														</fieldset>
													</fieldset>
												</div>
											</div>
										</fieldset>
										<fieldset
											class="my-0 py-0 mt-3"
											:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
											v-if="project.projectType === 1 || project.projectType === 4"
										>
											<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
												{{ FormMSG(40, 'Invoice Parameter') }}
											</legend>
											<div class="pb-3">
												<div class="alert alert-warning" role="alert">
													<div class="d-flex align-items-center" style="color: rgba(0, 0, 0, 0.6)">
														<div class="pr-3" style="font-size: 1.5rem">
															<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
														</div>
														<div class="mt-0" :style="`font-size: ${$screen.width <= 576 ? '.9' : '1'}rem;`">
															{{
																FormMSG(
																	41,
																	`You should specify your own invoice number format, otherwise the default format will be chosen automatically. e.g: "1"`
																)
															}}
														</div>
													</div>
												</div>
												<div class="d-flex flex-row">
													<b-form-radio-group
														v-model="selectedRadioInvoice"
														:options="radioInvoiceOptions"
														class="lh-22"
														@change="handleChangeInvoiceParameter"
													></b-form-radio-group>
												</div>
												<div class="d-block mt-2">
													<fieldset class="my-0 pb-3 w-100" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
														<fieldset
															class="my-0 pb-3 w-100"
															:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
														>
															<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
																{{ FormMSG(36, 'Preview') }}
															</legend>
															<div class="d-flex flex-row text-muted">
																{{ FormMSG(37, 'e.g:') }} <span class="pl-1 last-number-format">{{ renderInvoiceFormat }}</span>
															</div>
														</fieldset>
														<div v-if="!selectedRadioInvoice">
															<fieldset
																class="my-0 pb-2 w-100"
																:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
															>
																<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
																	{{ FormMSG(61, 'Define length') }}
																</legend>
																<div class="d-flex flex-row">
																	<div class="pr-2" style="margin-top: 2px">1</div>
																	<div class="w-100">
																		<b-form-input
																			v-model="invoiceInitialLength"
																			type="range"
																			min="1"
																			max="10"
																			step="1"
																			@change="handleChangeInvoiceInitialLength"
																		></b-form-input>
																	</div>
																	<div class="pl-2" style="margin-top: 2px">10</div>
																</div>
																<div class="justify-content-center">
																	<div class="w-100 text-center text-success">{{ invoiceInitialLength }}</div>
																</div>
															</fieldset>
															<fieldset
																class="my-0 pb-3 w-100"
																:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
															>
																<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
																	{{ FormMSG(35, 'Choose year') }}
																</legend>
																<div class="d-flex flex-row">
																	<b-form-radio-group
																		v-model="selectedRadioInvoiceYear"
																		:options="radioInvoiceYearOptions"
																		class="lh-22"
																		@change="handleChangeInvoiceYear"
																	></b-form-radio-group>
																</div>
															</fieldset>
															<fieldset
																class="my-0 pb-0 w-100"
																:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
															>
																<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
																	{{ FormMSG(33, 'Define prefix') }}
																</legend>
																<b-row>
																	<b-col sm="6">
																		<b-form-group label-for="invoiceInitialText">
																			<b-form-input
																				v-model.trim="invoiceInitialText"
																				type="text"
																				:placeholder="FormMSG(60, 'e.g: TGS ...')"
																				@input="handleChangeInvoiceInitialText"
																			></b-form-input>
																		</b-form-group>
																	</b-col>
																</b-row>
															</fieldset>
														</div>
														<fieldset
															class="my-0 pb-0 w-100"
															:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
														>
															<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
																{{ FormMSG(68, 'Enter your last number') }}
															</legend>
															<b-row>
																<b-col sm="6">
																	<b-form-group label-for="invoiceLastId">
																		<b-form-input
																			v-model="project.lastInvoiceNumber"
																			type="number"
																			step="1"
																			min="0"
																			max="100000"
																			:placeholder="'e.g: 101'"
																			:state="lastNumberValidation(project.lastInvoiceNumber)"
																			@input="handleChangeInvoiceLastNumber"
																		></b-form-input>
																		<b-form-invalid-feedback :state="lastNumberValidation(project.lastInvoiceNumber)">
																			{{ FormMSG(34, 'Length must be 0-100000') }}
																		</b-form-invalid-feedback>
																	</b-form-group>
																</b-col>
															</b-row>
														</fieldset>
													</fieldset>
												</div>
											</div>
										</fieldset>
										<fieldset
											class="my-0 py-0 mt-3"
											:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
											v-if="project.projectType === 0"
										>
											<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
												{{ FormMSG(740, 'Purchase Order parameter') }}
											</legend>
											<div class="pb-3">
												<div class="alert alert-warning" role="alert">
													<div class="d-flex align-items-center" style="color: rgba(0, 0, 0, 0.6)">
														<div class="pr-3" style="font-size: 1.5rem">
															<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
														</div>
														<div class="mt-0" :style="`font-size: ${$screen.width <= 576 ? '.9' : '1'}rem;`">
															{{
																FormMSG(
																	41,
																	`You should specify your own invoice number format, otherwise the default format will be chosen automatically. e.g: "1"`
																)
															}}
														</div>
													</div>
												</div>
												<div class="d-flex flex-row">
													<b-form-radio-group
														v-model="selectedRadioPo"
														:options="radioInvoiceOptions"
														class="lh-22"
														@change="handleChangePoParameter"
													/>
												</div>
												<div class="d-block mt-2">
													<fieldset class="my-0 pb-3 w-100" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
														<fieldset
															class="my-0 pb-3 w-100"
															:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
														>
															<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
																{{ FormMSG(36, 'Preview') }}
															</legend>
															<div class="d-flex flex-row text-muted">
																{{ FormMSG(37, 'e.g:') }} <span class="pl-1 last-number-format">{{ renderPoFormat }}</span>
															</div>
														</fieldset>
														<div v-if="!selectedRadioPo">
															<fieldset
																class="my-0 pb-2 w-100"
																:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
															>
																<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
																	{{ FormMSG(61, 'Define length') }}
																</legend>
																<div class="d-flex flex-row">
																	<div class="pr-2" style="margin-top: 2px">1</div>
																	<div class="w-100">
																		<b-form-input
																			v-model="poInitialLength"
																			type="range"
																			min="1"
																			max="10"
																			step="1"
																			@change="handleChangePoInitialLength"
																		></b-form-input>
																	</div>
																	<div class="pl-2" style="margin-top: 2px">10</div>
																</div>
																<div class="justify-content-center">
																	<div class="w-100 text-center text-success">{{ poInitialLength }}</div>
																</div>
															</fieldset>
															<fieldset
																class="my-0 pb-3 w-100"
																:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
															>
																<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
																	{{ FormMSG(35, 'Choose year') }}
																</legend>
																<div class="d-flex flex-row">
																	<b-form-radio-group
																		v-model="selectedRadioPoYear"
																		:options="radioInvoiceYearOptions"
																		class="lh-22"
																		@change="handleChangePoYear"
																	/>
																</div>
															</fieldset>
															<fieldset
																class="my-0 pb-0 w-100"
																:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
															>
																<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
																	{{ FormMSG(33, 'Define prefix') }}
																</legend>
																<b-row>
																	<b-col sm="6">
																		<b-form-group label-for="invoiceInitialText">
																			<b-form-input
																				v-model.trim="poInitialText"
																				type="text"
																				:placeholder="FormMSG(60, 'e.g: PO ...')"
																				@input="handleChangePoInitialText"
																			></b-form-input>
																		</b-form-group>
																	</b-col>
																</b-row>
															</fieldset>
														</div>
														<fieldset
															class="my-0 pb-0 w-100"
															:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
														>
															<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
																{{ FormMSG(68, 'Enter your last number') }}
															</legend>
															<b-row>
																<b-col sm="6">
																	<b-form-group label-for="invoiceLastId">
																		<b-form-input
																			v-model="project.lastPoNumber"
																			type="number"
																			step="1"
																			min="0"
																			max="100000"
																			:placeholder="'e.g: 101'"
																			:state="lastNumberValidation(project.lastPoNumber)"
																			@input="handleChangePoLastNumber"
																		></b-form-input>
																		<b-form-invalid-feedback :state="lastNumberValidation(project.lastPoNumber)">
																			{{ FormMSG(34, 'Length must be 0-100000') }}
																		</b-form-invalid-feedback>
																	</b-form-group>
																</b-col>
															</b-row>
														</fieldset>
													</fieldset>
												</div>
											</div>
										</fieldset>
									</div>
								</b-col>
								<b-col sm="3" class="text-center" v-if="!isModal" :class="`${$screen.width < 992 ? 'mt-3' : ''}`">
									<div :class="`${$screen.width > 576 ? 'sidenav-sticky sticky-top' : ''}`" style="z-index: 0">
										<b-row :class="`${$screen.width <= 576 ? 'footer-fixed' : 'card mb-2 pb-0 px-0'}`">
											<b-col cols="12">
												<b-button
													class="w-100 d-flex justify-content-center align-items-center"
													type="submit"
													variant="outline-primary"
													:disabled="disableSave"
												>
													<b-spinner v-show="isLoading" class="mr-2" small></b-spinner>
													<component :is="getLucideIcon('Check')" :size="20" class="mr-2" v-if="!isLoading" :stroke-width="2.5" />
													<div style="margin-top: 1px">{{ this.addSaveLabel() }}</div>
												</b-button>
											</b-col>
										</b-row>
										<b-row>
											<b-col cols="12">
												<b-button class="btn w-100" variant="primary" v-if="this.showGoBack" @click="viewUsers">{{
													this.FormMSG(17, 'Users')
												}}</b-button>
											</b-col>
										</b-row>
										<b-row>
											<b-col cols="12">
												<b-button class="btn w-100" variant="primary" v-if="this.showGoBack" @click="goBack">{{
													this.FormMSG(10, 'Go back')
												}}</b-button>
											</b-col>
										</b-row>
										<b-row>
											<b-col cols="12">
												<b-button class="btn w-100" variant="primary" v-if="this.showAddPme" @click="addPme">{{
													this.FormMSG(110, 'Add new Legal Entity')
												}}</b-button>
											</b-col>
										</b-row>
										<b-row>
											<b-col cols="12">
												<MobileProfilePicCaptureBtn
													v-if="$screen.width <= 567 || $isPwa()"
													parent-type="project"
													:parentId="project.id"
													:label-button="FormMSG(43, 'Change project picture')"
													crop-type="default"
													use-button
													@change="handleImgsTaken"
												/>
												<b-button
													v-if="$screen.width > 567"
													class="w-100 mt-0"
													variant="outline-secondary"
													size="md"
													@click="startCapture"
												>
													<i class="icon-camera"></i> {{ this.FormMSG(43, 'Change project picture') }}
												</b-button>
											</b-col>
										</b-row>
										<b-row>
											<b-col md="12">
												<b-form-group v-model="project.picture">
													<img v-bind:src="this.imageName" class="project-picture-preview" alt="pict" />
												</b-form-group>
											</b-col>
										</b-row>
									</div>
								</b-col>
							</b-row>
						</b-form>
					</div>
				</b-col>
			</b-row>
			<NewCapture
				v-model="capturePict"
				:key="refreshTokenCaptureItem"
				parentType="project"
				:parentId="project.id"
				@change="handleImgsTaken"
				auto-crop-capture
			/>
			<holidays-modal :open="showHolidaysModal" @holidays-modal:close="showHolidaysModal = false" />
			<!--only-one-crop-mode -->
			<loading :active.sync="isLoading" :is-full-page="true"></loading>
			<b-modal
				header-class="header-class-modal-doc-package"
				ref="my-modal"
				title="Crop Picture"
				size="cropSize"
				v-model="cropPict"
				@ok="endCrop"
				@cancel="endCropCancel"
				ok-variant="success"
				ok-title="Confirm Save"
				cancel-title="Cancel"
			>
				<CropPicture ref="myCropPicture"> </CropPicture>
			</b-modal>
			<b-modal
				header-class="header-class-modal-doc-package"
				:title="FormMSG(62, 'Create new Legal Entity?')"
				class="modal-dialog modal-lg"
				v-model="newPmeModal"
				@ok="newPmeConfirmed()"
				ok-variant="primary"
				:ok-title="this.FormMSG(67, 'Create Entity')"
			>
				<b-form-group :label="FormMSG(109, 'New Entity Name:')" label-for="pmeName" :label-cols="6">
					<b-form-input v-model="newPmeName" type="text" id="pmeName"></b-form-input>
				</b-form-group>
				<b-form-group
					class="form-row align-items-center"
					:label="FormMSG(120, 'Copy Departments, Functions, Budget categories and HR templates of this entity?')"
					label-for="copyPmeConfig"
					:label-cols="10"
				>
					<b-form-checkbox size="lg" id="copyPmeConfig" v-model="copyPmeConfig"></b-form-checkbox>
				</b-form-group>
			</b-modal>
		</div>
	</errorContainer>
</template>

<script>
// import gql from 'graphql-tag'
import { store } from '@/store';

//import  isNil  from "@/shared/utils.js";
const isNil = (any) => {
	return any === undefined || any === null;
};
import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { Camera, CameraResultType } from '@capacitor/camera';
import languageMessages from '@/mixins/languageMessages';
import CropPicture from '@/components/CropPicture';
import CapturePicture from '@/components/CapturePicture';
import SupplierSelector from '@/components/SupplierSelector';
//import Capture from "@/components/ExpenseService/Capture";
import NewCapture from '@/components/CaptureModule/Main';
import Loading from 'vue-loading-overlay';
import { getProject, updMyProject, addProject, newSmeProject } from '@/cruds/project.crud';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import moment from 'moment';
import globalMixin from '@/mixins/global.mixin';
import MobileProfilePicCaptureBtn from '@/components/CaptureModule/MobileProfilePicCaptureBtn';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
// import * as icons from 'lucide-vue';
import PayrollCode from '@/components/Spaiectacle/PayrollCode';
import * as _ from 'lodash';
import CarbonClapOption from './CarbonClapOption';
import HolidaysModal from '@/components/Modals/HolidaysModal';

// Variable doit être dans la query : https://github.com/graphql/graphiql/issues/156

// Variable doit être dans la query : https://github.com/graphql/graphiql/issues/156

const keyYearFormat = new Map();
keyYearFormat.set('{YYYY}', 2);
keyYearFormat.set('{YY}', 1);
keyYearFormat.set('', 0);

const valueYearFormat = new Map();
valueYearFormat.set(2, '{YYYY}');
valueYearFormat.set(1, '{YY}');
valueYearFormat.set(0, '');

export default {
	name: 'ProjectForm',
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		projectFormId: {
			type: [Number, String],
			required: false,
			default: 0
		},
		isCreateWithModal: { type: Boolean, required: false, default: false },
		isModal: { type: Boolean, required: false, default: false },
		canUpdate: { type: Boolean, required: false, default: false },
		canCreate: { type: Boolean, required: false, default: false },
		licenseId: { type: [Number, String], required: false, default: 0 }
	},
	data() {
		return {
			erreur: {},
			capturePict: false,
			cropPict: false,
			disableSaveButton: true,
			isLoading: false,
			refreshTokenCaptureItem: 0,
			enableProjectTypeChoice: false,
			newPmeModal: false,
			copyPmeConfig: false,
			newPmeName: '',
			project: {
				licenseID: 0,
				titre: '',
				production: '',
				realisateur: '',
				timeZone: 'CET',
				language: 0,
				currency: 0,
				ccLogin: '',
				ccPassword: '',
				distanceUnit: 0,
				projectType: 0,
				productionType: 0,
				filmLength: 0,
				numEpisode: 0,
				shootingFormat: 0,
				carbonModel: 1,
				greenReporting: 1,
				useOvertime: true,
				useOvt1: true,
				useOvt2: true,
				useHomeToWorkTime: true,
				useTransport: true,
				useLunchPerDiem: true,
				useHotelPerDiem: true,
				useTimesheet: true,
				useExpense: true,
				usePurchaseOrder: true,
				useDocument: true,
				useCallSheet: true,
				useAccommodation: true,
				useTipOfDay: true,
				useEntryForCrewTimesheet: true,
				useEntryForCrewPo: true,
				useEntryForCrewExpenses: true,
				useEntryForCrew: true,
				useGreenSupplier: true,
				useTask: true,
				usePlanning: true,
				useClient: true,
				useBudget: true,
				useNightHours: true,
				useTooEarlyHours: true,
				useSixthDay: true,
				useLunchTime: true,
				useStageDay: true,
				useBeforeCallTime: true,
				useWeeklyOvt: true,
				useWeeklyOvt1: true,
				useWeeklyOvt2: true,
				useSeventhDay: true,
				useSundayRate: true,
				picture: '',
				songMusic: '',
				coProducers: '',
				invoiceDetails: '',
				addressId: 0,
				startDate: '2021-10-13T00:00:00Z',
				endDate: '2021-10-13T00:00:00Z',
				customerId: '',
				numberOfUser: 10,
				supportLevel: 1,
				vatPercent: 0,
				useForCrewInvoice: false,
				largeAccount: false,
				largeAccountPaid: false,
				lastOrderFormat: '',
				lastOfferFormat: '',
				lastInvoiceFormat: '',
				lastInvoiceNumber: 0,
				lastOfferNumber: 0,
				lastOrderNumber: 0,
				lastPoFormat: '',
				lastPoNumber: 0,
				userName: '',
				hodName: '',
				managerName: '',
				shootingStartDate: null,
				wrapStartDate: null,
				useExpenseTravel: true,
				useExpenseTicket: true,
				useExpenseInvoice: true,
				reference: '',
				payrollInterface: 0,
				allowTSEntryOutsideContract: false,
				allowPOEntryOutsideContract: false,
				allowExpenseEntryOutsideContract: false,
				analyticPeriodType: 0,
				ccShareReporting: '',
				useKlippaOcr: false,
				regionalSetting: 0,
				useProduction: true,
				useContacts: true,
				useCrewSetup: true,
				useLocations: true,
				useExport: true,
				useImport: true,
				useFinance: true,
				useTimesheetPlanning: true,
				useGreen: true,
				useCarbonTemplate: true,
				useGreenTable: true,
				useReporting: true,
				useCarbonRemoval: true,
				useWaste: true
			},
			originalProject: {
				licenseID: 0,
				titre: '',
				production: '',
				realisateur: '',
				timeZone: 'CET',
				language: 0,
				currency: 0,
				distanceUnit: 0,
				projectType: 0,
				productionType: 0,
				filmLength: 0,
				numEpisode: 0,
				shootingFormat: 0,
				carbonModel: 1,
				greenReporting: 1,
				useOvertime: true,
				useOvt1: true,
				useOvt2: true,
				useHomeToWorkTime: true,
				useTransport: true,
				useLunchPerDiem: true,
				useHotelPerDiem: true,
				useTimesheet: true,
				useExpense: true,
				usePurchaseOrder: true,
				useDocument: true,
				useCallSheet: true,
				useAccommodation: true,
				useTipOfDay: true,
				useEntryForCrewTimesheet: true,
				useEntryForCrewPo: true,
				useEntryForCrewExpenses: true,
				useEntryForCrew: true,
				useGreenSupplier: true,
				useTask: true,
				usePlanning: true,
				useClient: true,
				useBudget: true,
				useNightHours: true,
				useTooEarlyHours: true,
				useSixthDay: true,
				useLunchTime: true,
				useStageDay: true,
				useBeforeCallTime: true,
				useWeeklyOvt: true,
				useWeeklyOvt1: true,
				useWeeklyOvt2: true,
				useSeventhDay: true,
				useSundayRate: true,
				picture: '',
				songMusic: '',
				coProducers: '',
				invoiceDetails: '',
				addressId: 0,
				startDate: '2021-10-13T00:00:00Z',
				endDate: '2021-10-13T00:00:00Z',
				customerId: '',
				numberOfUser: 10,
				supportLevel: 1,
				vatPercent: 0,
				useForCrewInvoice: false,
				largeAccount: false,
				largeAccountPaid: false,
				lastOrderFormat: '',
				lastOfferFormat: '',
				lastInvoiceFormat: '',
				lastInvoiceNumber: 0,
				lastOfferNumber: 0,
				lastOrderNumber: 0,
				userName: '',
				hodName: '',
				managerName: '',
				shootingStartDate: null,
				wrapStartDate: null,
				useExpenseTravel: true,
				useExpenseTicket: true,
				useExpenseInvoice: true,
				reference: '',
				payrollInterface: 0,
				allowTSEntryOutsideContract: false,
				allowPOEntryOutsideContract: false,
				allowExpenseEntryOutsideContract: false,
				analyticPeriodType: 0,
				ccLogin: '',
				ccPassword: '',
				ccShareReporting: '',
				useKlippaOcr: false,
				lastPoFormat: '',
				lastPoNumber: 0,
				regionalSetting: 0
			},
			errors: [],
			show: true,
			selectedRadioOffer: true,
			selectedRadioOrder: true,
			selectedRadioInvoice: true,
			selectedRadioPo: true,
			offerInitialLength: 1,
			offerInitialText: '',
			orderInitialLength: 1,
			orderInitialText: '',
			invoiceInitialLength: 1,
			poInitialLength: 1,
			invoiceInitialText: '',
			poInitialText: '',
			selectedRadioOfferYear: 0,
			selectedRadioOrderYear: 0,
			selectedRadioInvoiceYear: 0,
			selectedRadioPoYear: 0,
			defaultFormat: `%01d`,
			isStartMobilePwaCaptureLoading: false,
			isSupplierUpdate: false,
			masks: {
				input: 'DD/MM/YYYY'
			},
			allowTSEntryOutsideContract: false,
			allowPOEntryOutsideContract: false,
			allowExpenseEntryOutsideContract: false,
			itterationAnalyticSelected: 1,
			selectedSupplier: null,
			legalEntities: [],
			showHolidaysModal: false
		};
	},
	components: {
		CarbonClapOption,
		Loading,
		CropPicture,
		CapturePicture,
		SupplierSelector,
		NewCapture,
		MobileProfilePicCaptureBtn,
		'v-date-picker': DatePicker,
		PayrollCode,
		HolidaysModal
	},
	watch: {
		canUpdate: 'executeSubmitForm',
		canCreate: 'executeCreateSubmitForm',
		Project: {
			handler: function (n) {
				if (!isNil(n)) {
					this.disableSave;
				}
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		this.$nextTick(async function () {
			//console.log("projectform in mounted this.$route.params.id:", this.$route.params.id);
			//console.log("projectform in mounted this.$route.params.licenseId:", this.$route.params.licenseId);
			//console.log("projectform in mounted this.$route.name:", this.$route.name);
			//console.log("projectformId:", this.projectFormId);
			let projectID = parseInt(this.projectFormId);
			if (!isNil(this.$route.name) && (this.$route.name == 'AddProjectForLicenseFromDev' || this.isCreateWithModal)) {
				// do not load project adding a new one
				this.enableProjectTypeChoice = true;
				projectID = -1;
				this.project.startDate = new Date().toJSON(); // .slice(0, 10);

				if (this.isCreateWithModal) {
					this.project.projectType = 4;
					this.project.licenseID = this.licenseId;
				}

				let endDate = new Date();
				endDate.setDate(endDate.getDate() + 90);
				this.project.endDate = endDate.toJSON();
				this.stSubscriptionEndDate = '2050-12-31T00:00:00Z';
				this.stUnpaidTrigger = '2050-12-31T00:00:00Z';
				this.stWaitForPaiement = '2050-12-31T00:00:00Z';
				this.stBlockingTime = '2050-12-31T00:00:00Z';
			} else if (!isNil(this.$route.name) && this.$route.name === 'UpdateProjectForLicenseFromDev') {
				// console.log("in submit8", this.$route.params.projectId);
				projectID = parseInt(this.$route.params.projectId);
			} else if (!isNil(this.$route.name) && this.$route.name === 'UpdateProjectFromProjects') {
				// console.log("in submit9", this.$route.params.projectId);
				projectID = parseInt(this.$route.params.projectId);
			} else if (!isNil(this.$route.params) && !isNil(this.$route.params.id) && +this.$route.params.id === 0) {
				// console.log("in submit19", this.$route.params.projectId);
				projectID = parseInt(store.projectID(), 10);
			}
			//console.log("projectID:", projectID)
			if (projectID >= 0) {
				this.project = await getProject(projectID);
				/**
				 * @TODO
				 * let multiselect
				 */
				this.selectedSupplier = this.project.legalEntities.length === 0 ? null : this.project.legalEntities[0].id;
				// console.log({ project: this.project });
				this.originalProject = { ...this.project };
				this.emitBusProject();
				this.project.shootingStartDate = !isNil(this.project.shootingStartDate) ? new Date(this.project.shootingStartDate) : '';
				this.project.wrapStartDate = !isNil(this.project.wrapStartDate) ? new Date(this.project.wrapStartDate) : '';

				// console.log("Project => ",this.project);
				if (this.project.projectType === 0 || this.project.projectType === 1 || this.project.projectType === 4) {
					this.setLabelFormatValue();
					// console.log({ Project: this.project })
					// console.log({ OriginalProject: this.originalProject })
				}

				if (
					this.project.useExpense &&
					isNil(this.project.useExpenseTravel) &&
					isNil(this.project.useExpenseTicket) &&
					isNil(this.project.useExpenseInvoice)
				) {
					this.project = {
						useExpenseTravel: true,
						useExpenseTicket: true,
						useExpenseInvoice: true,
						...this.project
					};
				}

				if (this.project.analyticPeriodType === 0) {
					this.itterationAnalyticSelected = 1;
				} else if ([1, 2, 3].includes(this.project.analyticPeriodType)) {
					this.itterationAnalyticSelected = 2;
				} else if (this.project.analyticPeriodType === 4) {
					this.itterationAnalyticSelected = 3;
				}
			}
		});
	},
	computed: {
		isDev() {
			return store.isDev();
		},
		diffDatesFilm() {
			const dates1 = moment(this.project.shootingStartDate);
			const dates2 = moment(this.project.wrapStartDate);
			const diffInDays = dates2.diff(dates1, 'days');
			if (diffInDays) {
				return diffInDays;
			} else {
				return 0;
			}
		},
		diffDates() {
			const date1 = moment(this.project.startDate);
			const date2 = moment(this.project.endDate);

			// Calculate the difference in days using the 'diff' method
			const diffInDays = date2.diff(date1, 'days');
			return diffInDays;
		},
		analyticPeriodOptions() {
			let menues = this.FormMenu(10005);
			let result = [];

			if (this.itterationAnalyticSelected === 2) {
				result = menues.filter((option) => ![0, 4].includes(option.value));
			}

			if (this.itterationAnalyticSelected === 3) {
				result = menues.filter((option) => option.value === 4);
			}

			return result;
		},
		itterationAnalyticOptions() {
			return [
				{ text: '1', value: 1 },
				{ text: '2', value: 2 },
				{ text: '3', value: 3 }
			];
		},
		allowEntryOutsideContract: {
			get() {
				return this.project.allowTSEntryOutsideContract || this.project.allowPOEntryOutsideContract || this.project.allowExpenseEntryOutsideContract;
			},
			set(newVal) {
				if (newVal === true) {
					if (
						!this.project.allowTSEntryOutsideContract &&
						!this.project.allowPOEntryOutsideContract &&
						!this.project.allowExpenseEntryOutsideContract
					) {
						this.project.allowTSEntryOutsideContract = newVal;
						this.project.allowPOEntryOutsideContract = newVal;
						this.project.allowExpenseEntryOutsideContract = newVal;
					}
				} else {
					this.project.allowTSEntryOutsideContract = newVal;
					this.project.allowPOEntryOutsideContract = newVal;
					this.project.allowExpenseEntryOutsideContract = newVal;
				}
			}
		},
		displayLargeAccount() {
			if (this.project.projectType === 1) {
				return true;
			} else {
				return false;
			}
		},
		disableLargeAccount() {
			if (this.project.projectType === 1 && store.isDev()) {
				return false;
			} else {
				return true;
			}
		},
		imageName() {
			if (this.project.picture && this.project.picture.length > 0) {
				const strImg = process.env.VUE_APP_GQL + '/images/' + this.project.picture;
				//  console.log("pictPath:",strImg);
				return strImg;
			} else {
				return 'img/brand/M.JPG';
			}
		},
		optionsForLanguages() {
			return this.FormMenu(1);
		},
		optionForFormat() {
			return this.FormMenu(1355);
		},
		optionForType() {
			let type = [
				{
					label: this.FormMSG(1000, 'Film'),
					options: this.FormMenu(1350)
				},
				{
					label: this.FormMSG(1001, 'TV/ Audiovisual'),
					options: this.FormMenu(1351)
				},
				{
					label: this.FormMSG(1002, 'Shows'),
					options: this.FormMenu(1352)
				},
				{
					label: this.FormMSG(1003, 'Publicity'),
					options: this.FormMenu(1353)
				},
				{
					label: this.FormMSG(1004, 'Other'),
					options: this.FormMenu(1354)
				}
			];
			return type;
		},
		showGoBack() {
			if (
				this.$route.name == 'AddProjectForLicenseFromDev' ||
				this.$route.name == 'UpdateProjectForLicenseFromDev' ||
				this.$route.name == 'UpdateProjectFromProjects'
			) {
				return true;
			} else {
				return false;
			}
		},
		showAddPme() {
			if (isNil(this.$route.name) && store.isPme()) {
				return true;
			}
			return false;
		},
		mapCurrencies() {
			return this.FormMenu(5);
		},
		mapTgsProjectType() {
			return this.FormMenu(1025);
		},
		mapCarbonModels() {
			return this.FormMenu(7);
		},
		mapGreenReporting() {
			let menus = this.FormMenu(1130);
			// console.log(menus);
			if (!store.isDev()) {
				if (parseInt(this.project.greenReporting) !== 2) {
					menus = menus.filter((menu) => parseInt(menu.value) !== 2);
				}
			}

			return menus;
		},
		disableSave() {
			return !this.projectIsModified();
		},
		radioOrderOptions() {
			return [
				{ text: this.FormMSG(44, 'Use default format'), value: true },
				{ text: this.FormMSG(45, 'Use my custom format'), value: false }
			];
		},
		radioOfferOptions() {
			return [
				{ text: this.FormMSG(44, 'Use default format'), value: true },
				{ text: this.FormMSG(45, 'Use my custom format'), value: false }
			];
		},
		radioInvoiceOptions() {
			return [
				{ text: this.FormMSG(44, 'Use default format'), value: true },
				{ text: this.FormMSG(45, 'Use my custom format'), value: false }
			];
		},
		radioOfferYearOptions() {
			return [
				{ text: this.FormMSG(46, 'YYYY'), value: 2 },
				{ text: this.FormMSG(47, 'YY'), value: 1 },
				{ text: this.FormMSG(59, 'None'), value: 0 }
			];
		},
		radioOrderYearOptions() {
			return [
				{ text: this.FormMSG(46, 'YYYY'), value: 2 },
				{ text: this.FormMSG(47, 'YY'), value: 1 },
				{ text: this.FormMSG(59, 'None'), value: 0 }
			];
		},
		radioInvoiceYearOptions() {
			return [
				{ text: this.FormMSG(46, 'YYYY'), value: 2 },
				{ text: this.FormMSG(47, 'YY'), value: 1 },
				{ text: this.FormMSG(59, 'None'), value: 0 }
			];
		},
		renderOfferFormat() {
			const incNumber = this.project.lastOfferNumber.toString();
			let formatYear = '';
			let year = '';

			if (!this.selectedRadioOffer) {
				if (this.selectedRadioOfferYear === 2) {
					formatYear = 'YYYY';
				} else if (this.selectedRadioOfferYear === 1) {
					formatYear = 'YY';
				}

				if (formatYear !== '') {
					year = new moment(new Date()).format(formatYear);
				}
			}

			return !this.selectedRadioOffer ? this.offerInitialText.toUpperCase() + year + incNumber.padStart(this.offerInitialLength, '0') : incNumber;
		},
		renderOrderFormat() {
			const incNumber = this.project.lastOrderNumber.toString();
			let formatYear = '';
			let year = '';

			if (!this.selectedRadioOrder) {
				if (this.selectedRadioOrderYear === 2) {
					formatYear = 'YYYY';
				} else if (this.selectedRadioOrderYear === 1) {
					formatYear = 'YY';
				}

				if (formatYear !== '') {
					year = new moment(new Date()).format(formatYear);
				}
			}

			return !this.selectedRadioOrder ? this.orderInitialText.toUpperCase() + year + incNumber.padStart(this.orderInitialLength, '0') : incNumber;
		},
		renderInvoiceFormat() {
			const incNumber = this.project.lastInvoiceNumber.toString();
			let formatYear = '';
			let year = '';

			if (!this.selectedRadioInvoice) {
				if (this.selectedRadioInvoiceYear === 2) {
					formatYear = 'YYYY';
				} else if (this.selectedRadioInvoiceYear === 1) {
					formatYear = 'YY';
				}
				if (formatYear !== '') {
					year = new moment(new Date()).format(formatYear);
				}
			}

			return !this.selectedRadioInvoice ? this.invoiceInitialText.toUpperCase() + year + incNumber.padStart(this.invoiceInitialLength, '0') : incNumber;
		},
		renderPoFormat() {
			const incNumber = this.project.lastPoNumber.toString();
			let formatYear = '';
			let year = '';

			if (!this.selectedRadioPo) {
				if (this.selectedRadioPoYear === 2) {
					formatYear = 'YYYY';
				} else if (this.selectedRadioPoYear === 1) {
					formatYear = 'YY';
				}
				if (formatYear !== '') {
					year = new moment(new Date()).format(formatYear);
				}
			}

			return !this.selectedRadioPo ? this.poInitialText.toUpperCase() + year + incNumber.padStart(this.poInitialLength, '0') : incNumber;
		},
		mapDistacneUnits() {
			return this.FormMenu(1330);
		},
		labelLegalEntity() {
			if (this.isFilmSingle) {
				return this.FormMSG(454, 'Employer legal entity');
			} else {
				return this.FormMSG(455, 'Legal entity details');
			}
		},
		mapPayrollInterface() {
			return this.FormMenu(1335);
		},
		regionalSettingOptions() {
			let menus = this.FormMenu(11023);

			menus.sort((a, b) => {
				if (a.text < b.text) {
					return -1;
				}
				if (a.text > b.text) {
					return 1;
				}

				return 0;
			});

			return menus;
		}
	},
	methods: {
		handleGreen(event, pos) {
			if (pos == 0) {
				this.project.useCarbonTemplate = event;
				this.project.useGreenTable = event;
				this.project.useReporting = event;
				this.project.useCarbonRemoval = event;
				this.project.useWaste = event;
				this.project.useTipOfDay = event;
			} else {
				if (
					!this.project.useCarbonTemplate &&
					!this.project.useGreenTable &&
					!this.project.useReporting &&
					!this.project.useCarbonRemoval &&
					!this.project.useWaste &&
					!this.project.useTipOfDay
				) {
					this.project.useGreen = false;
				} else {
					this.project.useGreen = true;
				}
			}
		},
		handleFinance(event, pos) {
			if (pos == 0) {
				this.project.useGreenSupplier = event;
				this.project.usePurchaseOrder = event;
				this.project.useExpense = event;
				this.project.useTimesheet = event;
				this.project.useTimesheetPlanning = event;
				this.project.useBudget = event;
				this.handleControlExpense(event, 0);
			} else {
				if (pos === 2) {
					this.handleControlExpense(event, 0);
				}
				if (
					!this.project.useGreenSupplier &&
					!this.project.usePurchaseOrder &&
					!this.project.useExpense &&
					!this.project.useTimesheet &&
					!this.project.useTimesheetPlanning &&
					!this.project.useBudget
				) {
					this.project.useFinance = false;
				} else {
					this.project.useFinance = true;
				}
			}
		},
		handleUseProd(event, pos) {
			if (pos == 0) {
				this.project.useTransport = event;
				this.project.useDocument = event;
				this.project.useCallSheet = event;
				this.project.useAccommodation = event;
				this.project.useContacts = event;
				this.project.useCrewSetup = event;
				this.project.useLocations = event;
				this.project.useExport = event;
				this.project.useImport = event;
			} else {
				if (
					!this.project.useTransport &&
					!this.project.useDocument &&
					!this.project.useCallSheet &&
					!this.project.useAccommodation &&
					!this.project.useContacts &&
					!this.project.useCrewSetup &&
					!this.project.useLocations &&
					!this.project.useExport &&
					!this.project.useImport
				) {
					this.project.useProduction = false;
				} else {
					this.project.useProduction = true;
				}
			}
		},
		handleCarbonClapOptionChange({ ccLogin, ccPassword, ccShareReporting }) {
			this.project.ccLogin = ccLogin;
			this.project.ccPassword = ccPassword;
			this.project.ccShareReporting = ccShareReporting;
		},
		handleCHangeABit() {
			console.log(this.project);
		},
		handleChangeItterationAnalytic(payload) {
			if (payload === 1) {
				this.project.analyticPeriodType = 0;
			} else if (payload === 2) {
				this.project.analyticPeriodType = 1;
			} else if (payload === 3) {
				this.project.analyticPeriodType = 4;
			}
		},
		projectIsModified() {
			// compare originalProject and Project to activate save button
			if (JSON.stringify(this.project) === JSON.stringify(this.originalProject)) {
				return false;
			}
			return true;
		},
		async startMobilePwaCapture() {
			store.state.capturedImage = '';
			store.state.imageCropped = '';

			const photo = await Camera.getPhoto({
				resultType: CameraResultType.Uri,
				allowEditing: true,
				quality: 100
			});

			const blob = await fetch(photo.webPath).then((r) => r.blob());
			let formData = new FormData();
			formData.append('uploadimage', blob);

			try {
				const savedImg = await this.$axios.$post('upload', formData, {
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'content-type': 'multipart/form-data'
					}
				});
				this.handleImgsTaken([savedImg]);
			} catch (e) {
				console.error({ e });
			}
			// this.isStartMobilePwaCaptureLoading = false
		},
		/**
		 *
		 * To send the Project variable by event
		 */
		emitBusProject() {
			this.$bus.$emit('bus:projectevent', this.project);
		},
		async executeSubmitForm() {
			if (this.canUpdate && this.canUpdate === true) {
				await this.submitForm();
				this.$emit('updatefinishevent');
			}
		},
		async executeCreateSubmitForm() {
			if (this.canCreate && this.canCreate === true) {
				await this.submitForm();
				this.$emit('createfinishevent', this.originalProject);
			}
		},
		handleAdminOwnerChange(supplier) {
			// console.log({ supplier });
			this.isSupplierUpdate = false;

			if (isNil(supplier)) return;
			this.selectedSupplier = +supplier.id;

			// @TODO review when we must to select one or more suppliers
			this.legalEntities = [];
			this.legalEntities.push(+supplier.id);

			this.isSupplierUpdate = true;
		},
		updateStartDate: function ($event) {
			if (this.project.startDate.startsWith('T')) {
				this.project.startDate = $event + this.project.startDate;
			} else {
				this.project.startDate = $event + this.project.startDate.substring(10);
			}
			// console.log("this.startDate:",this.project.startDate);
		},
		updateEndDate: function ($event) {
			if (this.project.endDate.startsWith('T')) {
				this.project.endDate = $event + this.project.endDate;
			} else {
				this.project.endDate = $event + this.project.endDate.substring(10);
			}
		},
		addPme() {
			this.newPmeModal = true;
		},
		async newPmeConfirmed() {
			let ProjectId = 0;
			if (!isNil(this.project.id)) {
				ProjectId = parseInt(this.project.id);
			}

			let Filter = {};
			Filter.allData = false;
			Filter.allConfig = this.copyPmeConfig;
			const PmeName = this.newPmeName;
			if (PmeName.length == 0) {
				alert(this.FormMSG(131, 'New entity name is empty!'));
				return;
			}
			this.isLoading = true;
			await newSmeProject(ProjectId, PmeName, Filter);
			this.isLoading = false;
			window.location.reload(true); // to force update project list in myproject
		},
		viewUsersLink(id) {
			return `/projects/${id.toString()}/users`;
		},
		viewUsers() {
			//console.log("viewUsers link:");
			const viewProjectUsersLink = this.viewUsersLink(this.project.id);
			//console.log("viewUsers link:", viewProjectUsersLink);
			this.$router.push({
				path: viewProjectUsersLink
			});
		},
		addSaveLabel() {
			if (this.$route.name == 'AddProjectForLicenseFromDev') {
				return this.FormMSG(11, 'Add project');
			} else {
				return this.FormMSG(12, 'Save');
			}
		},
		goBack() {
			this.$router.go(-1);
			// this.$router.replace({path: '/users'})
		},
		async saveProject() {
			this.isLoading = true;
			let addRequest = false;
			let licenseId = null;
			if (!isNil(this.$route.name) && (this.$route.name == 'AddProjectForLicenseFromDev' || this.isCreateWithModal)) {
				// console.log("in submit3", this.$route.params.licenseId);
				addRequest = true;
				licenseId = parseInt(this.$route.params.licenseId);
				// console.log("in ProjectFor addproject for licenseId:", licenseId);
				//}UpdateProjectForLicenseFromDev
			} else if (!isNil(this.$route.name) && this.$route.name == 'UpdateProjectForLicenseFromDev') {
				// console.log("in submit6", this.$route.params.licenseId);
				licenseId = parseInt(this.$route.params.licenseId);
				// console.log("in ProjectFor updproject for licenseId:", licenseId);
				//}UpdateProjectForLicenseFromDev
			}

			// Manage shootingStartDate and wrapStartDate
			this.manageStartDateWrapShootByAnalyticPeriod();

			let newProject = {};
			let projectId = 0;
			if (!isNil(this.project.id)) {
				projectId = parseInt(this.project.id);
			}
			newProject.id = 0;
			newProject.titre = this.project.titre;
			newProject.production = this.project.production;
			newProject.realisateur = this.project.realisateur;
			newProject.timeZone = this.project.timeZone;
			newProject.licenseID = parseInt(this.project.licenseID);
			newProject.currency = parseInt(this.project.currency);
			newProject.distanceUnit = parseInt(this.project.distanceUnit);
			newProject.projectType = parseInt(this.project.projectType);
			newProject.carbonModel = parseInt(this.project.carbonModel);
			newProject.greenReporting = parseInt(this.project.greenReporting);
			newProject.useOvertime = this.project.useOvertime;
			newProject.useOvt1 = this.project.useOvt1;
			newProject.useOvt2 = this.project.useOvt2;
			newProject.useHomeToWorkTime = this.project.useHomeToWorkTime;
			newProject.useTransport = this.project.useTransport;
			newProject.useLunchPerDiem = this.project.useLunchPerDiem;
			newProject.useHotelPerDiem = this.project.useHotelPerDiem;
			newProject.useTimesheet = this.project.useTimesheet;
			newProject.useExpense = this.project.useExpense;
			newProject.usePurchaseOrder = this.project.usePurchaseOrder;
			newProject.useDocument = this.project.useDocument;
			newProject.useCallSheet = this.project.useCallSheet;
			newProject.useAccommodation = this.project.useAccommodation;
			newProject.useTipOfDay = this.project.useTipOfDay;
			newProject.useGreenSupplier = this.project.useGreenSupplier;
			newProject.useTask = this.project.useTask;
			newProject.usePlanning = this.project.usePlanning;
			newProject.useClient = this.project.useClient;
			newProject.useBudget = this.project.useBudget;
			newProject.useNightHours = this.project.useNightHours;
			newProject.useTooEarlyHours = this.project.useTooEarlyHours;
			newProject.useSixthDay = this.project.useSixthDay;
			newProject.useLunchTime = this.project.useLunchTime;
			newProject.hideSalaryInfo = this.project.hideSalaryInfo;
			newProject.useStageDay = this.project.useStageDay;
			newProject.language = parseInt(this.project.language);
			newProject.picture = this.project.picture;
			newProject.coProducers = this.project.coProducers;
			newProject.songMusic = this.project.songMusic;
			newProject.invoiceDetails = this.project.invoiceDetails;
			newProject.useProduction = this.project.useProduction;
			newProject.useContacts = this.project.useContacts;
			newProject.useCrewSetup = this.project.useCrewSetup;
			newProject.useLocations = this.project.useLocations;
			newProject.useExport = this.project.useExport;
			newProject.useImport = this.project.useImport;
			newProject.useFinance = this.project.useFinance;
			newProject.useTimesheetPlanning = this.project.useTimesheetPlanning;
			newProject.useGreen = this.project.useGreen;
			newProject.useCarbonTemplate = this.project.useCarbonTemplate;
			newProject.useGreenTable = this.project.useGreenTable;
			newProject.useReporting = this.project.useReporting;
			newProject.useCarbonRemoval = this.project.useCarbonRemoval;
			newProject.useWaste = this.project.useWaste;
			newProject.startDate = this.project.startDate;
			newProject.endDate = this.project.endDate;
			newProject.customerId = this.project.customerId;
			newProject.vatPercent = parseFloat(this.project.vatPercent);
			newProject.numberOfUser = parseInt(this.project.numberOfUser);
			newProject.supportLevel = parseInt(this.project.supportLevel);
			newProject.useForCrewInvoice = this.project.useForCrewInvoice;
			newProject.largeAccount = this.project.largeAccount;
			newProject.largeAccountPaid = this.project.largeAccountPaid;
			newProject.lastOfferNumber = isNaN(parseInt(this.project.lastOfferNumber, 10)) ? 0 : parseInt(this.project.lastOfferNumber, 10);
			newProject.lastOrderNumber = isNaN(parseInt(this.project.lastOrderNumber, 10)) ? 0 : parseInt(this.project.lastOrderNumber, 10);
			newProject.lastInvoiceNumber = isNaN(parseInt(this.project.lastInvoiceNumber, 10)) ? 0 : parseInt(this.project.lastInvoiceNumber, 10);
			newProject.lastPoNumber = isNaN(parseInt(this.project.lastPoNumber, 10)) ? 0 : parseInt(this.project.lastPoNumber, 10);
			newProject.userName = this.project.userName;
			newProject.hodName = this.project.hodName;
			newProject.managerName = this.project.managerName;
			newProject.shootingStartDate = !isNil(this.project.shootingStartDate)
				? moment(this.project.shootingStartDate).format('YYYY-MM-DD') + 'T00:00:00Z'
				: '0001-01-01T00:00:00Z';
			newProject.wrapStartDate = !isNil(this.project.wrapStartDate)
				? moment(this.project.wrapStartDate).format('YYYY-MM-DD') + 'T00:00:00Z'
				: '0001-01-01T00:00:00Z';
			newProject.useBeforeCallTime = this.project.useBeforeCallTime;
			newProject.useWeeklyOvt = this.project.useWeeklyOvt;
			newProject.useWeeklyOvt1 = this.project.useWeeklyOvt1;
			newProject.useWeeklyOvt2 = this.project.useWeeklyOvt2;
			newProject.useSeventhDay = this.project.useSeventhDay;
			newProject.useSundayRate = this.project.useSundayRate;
			newProject.useDinnerPerDiem = this.project.useDinnerPerDiem;
			newProject.useAbroadPerDiem = this.project.useAbroadPerDiem;
			newProject.budgetLocked = this.project.budgetLocked;
			// newProject.useExpenseTravel = this.project.useExpenseTravel;
			// newProject.useExpenseTicket = this.project.useExpenseTicket;
			// newProject.useExpenseInvoice = this.project.useExpenseInvoice;
			newProject.useExpenseTravel = this.project.useExpenseTravel;
			newProject.useExpenseTicket = this.project.useExpenseTicket;
			newProject.useExpenseInvoice = this.project.useExpenseInvoice;
			newProject.reference = this.project.reference;
			newProject.payrollInterface = this.project.payrollInterface;
			newProject.useEntryForCrewExpenses = this.project.useEntryForCrewExpenses;
			newProject.useEntryForCrewPo = this.project.useEntryForCrewPo;
			newProject.useEntryForCrewTimesheet = this.project.useEntryForCrewTimesheet;
			newProject.useEntryForCrew = this.project.useEntryForCrew;
			newProject.allowTSEntryOutsideContract = this.project.allowTSEntryOutsideContract;
			newProject.allowPOEntryOutsideContract = this.project.allowPOEntryOutsideContract;
			newProject.allowExpenseEntryOutsideContract = this.project.allowExpenseEntryOutsideContract;
			newProject.analyticPeriodType = this.project.analyticPeriodType;
			newProject.shootingFormat = this.project.shootingFormat;
			newProject.productionType = this.project.productionType;
			newProject.filmLength = parseInt(this.project.filmLength, 10);
			newProject.numEpisode = parseInt(this.project.numEpisode, 10);
			newProject.ccLogin = this.project.ccLogin;
			newProject.ccPassword = this.project.ccPassword;
			newProject.ccShareReporting = this.project.ccShareReporting;

			newProject.useKlippaOcr = this.project.useKlippaOcr;
			newProject.maxExpenseItemsPerSheet = +this.project.maxExpenseItemsPerSheet;
			newProject.regionalSetting = +this.project.regionalSetting;
			// console.log({ newProject });
			newProject = this.getNewFormatValue(newProject);

			if (!isNil(licenseId)) {
				newProject.licenseID = licenseId;
				// console.log("in ProjectFor addproject for newProject.licenseId:", newProject.licenseID);
			}
			if (addRequest) {
				// console.log("in ProjectFor addproject this.startDate:",this.project.startDate);
				// console.log("in ProjectFor addproject for newProject:", newProject);
				if (this.isCreateWithModal) {
					newProject.licenseID = parseInt(store.state.myProject.licenseID);
					newProject.endDate = '2099-12-30T00:00:00Z';
				}
				if (store.isFilmSingleUser()) {
					this.project = await addProject(newProject, parseInt(store.state.myProject.id)).finally(() => {
						this.isLoading = false;
					});
					// this.successSend(this.FormMSG(156, 'Added project successfully'));
				} else {
					this.project = await addProject(newProject, 0).finally(() => {
						this.isLoading = false;
					});
					// this.successSend(this.FormMSG(156, 'Added project successfully'));
				}

				this.emitBusProject();
				// console.log("in ProjectFor addproject for this.project:", this.project);
			} else {
				await updMyProject(projectId, newProject, this.legalEntities).finally(() => {
					this.isLoading = false;
				});
				await this.$store.dispatch('refreshToken');
				await store.reloadMyProject();

				this.$toast({
					title: this.FormMSG(252, 'Information'),
					variant: 'success',
					message: this.FormMSG(1300, 'Project modification saved successfully')
				});

				this.emitBusProject();
				newProject.id = projectId;
				store.state.myProject = newProject;
				store.state.noTranslationMenus = {
					userName: newProject.userName,
					hodName: newProject.hodName,
					managerName: newProject.managerName
				};
			}
			// set originalProject to new or updated project
			this.originalProject = { ...this.project };
			if (this.$route.name == 'AddProjectForLicenseFromDev') {
				this.goBack();
			}
		},
		manageStartDateWrapShootByAnalyticPeriod() {
			switch (this.project.analyticPeriodType) {
				case 0:
					this.project.shootingStartDate = this.project.startDate;
					this.project.wrapStartDate = this.project.startDate;
					break;
				case 1:
					this.project.wrapStartDate = this.project.shootingStartDate;
					break;
				case 2:
					this.project.startDate = this.project.shootingStartDate;
					break;
				case 3:
					this.project.shootingStartDate = this.project.wrapStartDate;
					break;
			}
		},
		submitForm: async function () {
			// console.log("in submit");
			this.errors = [];

			// e.preventDefault();
			if (this.project.projectType == 0) {
				if (!(this.project.titre && this.project.production && this.project.realisateur && this.project.timeZone)) {
					if (!this.project.titre) {
						this.errors.push(this.FormMSG(53, 'Title required.'));
					}
					if (!this.project.production) {
						this.errors.push(this.FormMSG(54, 'Production required.'));
					}
					if (!this.project.realisateur) {
						this.errors.push(this.FormMSG(55, 'Producer required.'));
					}
					if (!this.project.timeZone) {
						this.errors.push(this.FormMSG(56, 'TimeZone required.'));
					}

					return true;
				}
			} else {
				if (!(this.project.titre && this.project.timeZone)) {
					if (!this.project.titre) {
						this.errors.push(this.FormMSG(53, 'Title required.'));
					}

					if (!this.project.timeZone) {
						this.errors.push(this.FormMSG(56, 'TimeZone required.'));
					}

					return true;
				}
			}
			//console.log("in submit2");
			await this.saveProject();
		},
		resetForm: function (e) {
			this.errors = [];
			this.project.titre = '';
			this.project.production = '';
			this.project.realisateur = '';
			this.project.timeZone = '';
			this.project.currency = 0;
			this.project.distanceUnit = 0;
			this.project.projectType = 0;
			this.project.carbonModel = 0;
			this.project.greenReporting = 0;
			this.project.useOvertime = true;
			this.project.useTooEarlyHours = true;
			this.project.useSixthDay = true;
			this.project.useLunchTime = true;
			this.project.useStageDay = true;
			this.project.useOvt1 = true;
			this.project.useOvt2 = true;
			this.project.useHomeToWorkTime = true;
			this.project.useTransport = true;
			this.project.useLunchPerDiem = true;
			this.project.useHotelPerDiem = true;
			this.project.useTimesheet = true;
			this.project.useExpense = true;
			this.project.usePurchaseOrder = true;
			this.project.useDocument = true;
			this.project.useAccommodation = true;
			this.project.useTipOfDay = true;
			(this.project.useEntryForCrewTimesheet = true),
				(this.project.useEntryForCrew = true),
				(this.project.useEntryForCrewPo = true),
				(this.project.useEntryForCrewExpenses = true),
				(this.project.useGreenSupplier = true);
			this.project.useTask = true;
			this.project.hideSalaryInfo = false;
			this.project.usePlanning = true;
			this.project.useClient = true;
			this.project.useBudget = true;
			this.project.useNightHours = true;
			this.project.useTooEarlyHours = true;
			this.project.useSixthDay = true;
			this.project.useLunchTime = true;
			this.project.useStageDay = true;
			this.project.picture = '';
			this.project.useBeforeCallTime = true;
			this.project.useWeeklyOvt = true;
			this.project.useWeeklyOvt1 = true;
			this.project.useWeeklyOvt2 = true;
			this.project.useSeventhDay = true;
			this.project.useSundayRate = true;
			this.useDinnerPerDiem = true;
			this.useAbroadPerDiem = true;
			this.project.budgetLocked = false;
			this.project.useExpenseTravel = true;
			this.project.useExpenseTicket = true;
			this.project.useExpenseInvoice = true;
			this.project.reference = '';
			this.project.payrollInterface = 0;
			this.project.allowTSEntryOutsideContract = false;
			this.project.allowPOEntryOutsideContract = false;
			this.project.allowExpenseEntryOutsideContract = false;
			this.project.maxExpenseItemsPerSheet = 0;
			this.project.regionalSetting = 0;
		},
		startCapture: function () {
			// initialise global store variable and open dialog to capture and crop
			// console.log("instartcapture");
			store.state.capturedImage = '';
			store.state.imageCropped = '';
			this.disableSaveButton = true;
			this.capturePict = true;
			// console.log("instartcapture store.state.capturedImage", store.state.capturedImage);
		},
		cancelCapture() {
			store.state.capturedImage = '';
			// stop camera
			this.$refs.CaptCompo.onStop();
			this.capturePict = false;
		},
		endCapture: function (item) {
			// stop camera
			this.$refs.CaptCompo.onStop();
			// copy store image name into profile data
			this.capturePict = false;
			// start cropping if image was captured
			if (store.state.capturedImage.length > 0) {
				// an image was captured
				// console.log("in End capture");
				store.state.imageToCrop = store.state.capturedImage;
				this.$refs.myCropPicture.bind(); // force the component to link the new image to the display
				this.cropPict = true;
			}
		},
		endCrop: function (item) {
			// save image to server
			//console.log("this.$refs.myCropPicture.cropped:",this.$refs.myCropPicture.cropped);
			if (store.state.imageCropped.length > 0) {
				this.saveImage(store.state.imageCropped);
			}
			this.cropPict = false;
		},
		endCropCancel: function (item) {
			store.state.imageCropped = '';
			this.cropPict = false;
		},
		b64toFile(b64Data, filename, contentType) {
			const sliceSize = 512;
			const byteCharacters = atob(b64Data);
			let byteArrays = [];
			for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
				const slice = byteCharacters.slice(offset, offset + sliceSize);
				let byteNumbers = new Array(slice.length);

				for (let i = 0; i < slice.length; i++) {
					byteNumbers[i] = slice.charCodeAt(i);
				}
				let byteArray = new Uint8Array(byteNumbers);
				byteArrays.push(byteArray);
			}
			const file = new File(byteArrays, filename, {
				type: contentType
			});
			return file;
		},
		saveImage(imgData) {
			this.isLoading = true;
			let retStr = '';
			// remove header data:image...
			const base64Data = imgData.replace(/^data:image\/jpeg;base64,/, '');
			// create in memory file
			const fil = this.b64toFile(base64Data, 'test.jpg', 'jpg');
			let formData = new FormData(); // instantiate it
			// suppose you have your file ready
			formData.set('uploadimage', fil);
			const config = {
				headers: {
					Authorization: `Bearer ${store.state.token}`,
					'content-type': 'multipart/form-data'
				}
			};
			const urlStr = process.env.VUE_APP_GQL + '/upload';
			axios
				.post(urlStr, formData, config)
				.then((response) => {
					retStr = response.data;
					// console.log("response.data:", retStr);
					this.myProfile.picture = retStr;
					store.state.imageCropped = '';
					// console.log("response.data:", retStr);
					this.updateProjectPictureName();
					this.isLoading = false;
				})
				.catch((error) => {
					// console.log(error);
				})
				.finally(() => (this.isLoading = false));
			return retStr;
		},
		handleImgsTaken(imgs) {
			// console.log('handleImgsTaken', imgs);
			const retStr = imgs[0];
			this.project.picture = retStr;
			// console.log('this.project.picture ', this.project.picture);
			store.state.imageCropped = '';
			this.updateProjectPictureName();
			this.isLoading = false;
			this.refreshTokenCaptureItem = this.refreshTokenCaptureItem + 1;
		},
		updateProjectPictureName() {
			this.saveProject();
		},
		lastNumberValidation(value) {
			return value !== '' && !/^(0[0-9]+)|^(\-[0-9]+)/.test(value);
		},
		setLabelFormatValue() {
			const expLengthNumber = /(%0[0-9]+d)$/g;
			const expYearFormat = /\{[Y]+\}/g;

			// offer
			const isDefaultFormatOffer = this.isDefaultFormat(this.project.lastOfferFormat);
			this.selectedRadioOffer = isDefaultFormatOffer;

			if (this.project.lastOfferFormat === '') {
				this.project.lastOfferFormat = this.defaultFormat;
			}

			if (!isDefaultFormatOffer) {
				let format = this.project.lastOfferFormat;
				this.offerInitialLength = this.getLengthFormat(expLengthNumber, format);
				format = format.replace(expLengthNumber, '');
				const matchYearFormat = format.match(expYearFormat) ? format.match(expYearFormat)[0] : '';
				this.selectedRadioOfferYear = keyYearFormat.get(matchYearFormat);
				format = format.replace(matchYearFormat, '');
				this.offerInitialText = format;
			}

			// order
			const isDefaultFormatOrder = this.isDefaultFormat(this.project.lastOrderFormat);
			this.selectedRadioOrder = isDefaultFormatOrder;

			if (this.project.lastOrderFormat === '') {
				this.project.lastOrderFormat = this.defaultFormat;
			}

			if (!isDefaultFormatOrder) {
				let format = this.project.lastOrderFormat;
				this.orderInitialLength = this.getLengthFormat(expLengthNumber, format);
				format = format.replace(expLengthNumber, '');
				const matchYearFormat = format.match(expYearFormat) ? format.match(expYearFormat)[0] : '';
				this.selectedRadioOrderYear = keyYearFormat.get(matchYearFormat);
				format = format.replace(matchYearFormat, '');
				this.orderInitialText = format;
			}

			// invoice
			const isDefaultFormatInvoice = this.isDefaultFormat(this.project.lastInvoiceFormat);
			this.selectedRadioInvoice = isDefaultFormatInvoice;

			if (this.project.lastInvoiceFormat === '') {
				this.project.lastInvoiceFormat = this.defaultFormat;
			}

			if (!isDefaultFormatInvoice) {
				let format = this.project.lastInvoiceFormat;
				this.invoiceInitialLength = this.getLengthFormat(expLengthNumber, format);
				format = format.replace(expLengthNumber, '');
				const matchYearFormat = format.match(expYearFormat) ? format.match(expYearFormat)[0] : '';
				this.selectedRadioInvoiceYear = keyYearFormat.get(matchYearFormat);
				format = format.replace(matchYearFormat, '');
				this.invoiceInitialText = format;
			}

			// purchase order
			const isDefaultFormatPo = this.isDefaultFormat(this.project.lastPoFormat);
			this.selectedRadioPo = isDefaultFormatPo;

			if (this.project.lastPoFormat === '') {
				this.project.lastPoFormat = this.defaultFormat;
			}

			if (!isDefaultFormatPo) {
				let format = this.project.lastPoFormat;
				this.poInitialLength = this.getLengthFormat(expLengthNumber, format);
				format = format.replace(expLengthNumber, '');
				const matchYearFormat = format.match(expYearFormat) ? format.match(expYearFormat)[0] : '';
				this.selectedRadioPoYear = keyYearFormat.get(matchYearFormat);
				format = format.replace(matchYearFormat, '');
				this.poInitialText = format;
			}
		},
		isDefaultFormat(value) {
			return value === this.defaultFormat || value === '';
		},
		getLengthFormat(regExp, value) {
			return parseInt(value.match(regExp)[0].replace(/[%0d]/g, ''), 10);
		},
		getNewFormatValue(project) {
			const offerNumberFormat = `%0${this.offerInitialLength}d`;

			if (this.selectedRadioOffer) {
				this.project.lastOfferFormat = this.defaultFormat;
			} else {
				const yearFormat = valueYearFormat.get(this.selectedRadioOfferYear);
				this.project.lastOfferFormat = this.offerInitialText.toUpperCase() + yearFormat + offerNumberFormat;
			}
			project.lastOfferFormat = this.project.lastOfferFormat;

			const orderNumberFormat = `%0${this.orderInitialLength}d`;

			if (this.selectedRadioOrder) {
				this.project.lastOrderFormat = this.defaultFormat;
			} else {
				const yearFormat = valueYearFormat.get(this.selectedRadioOrderYear);
				this.project.lastOrderFormat = this.orderInitialText.toUpperCase() + yearFormat + orderNumberFormat;
			}
			project.lastOrderFormat = this.project.lastOrderFormat;

			const invoiceNumberFormat = `%0${this.invoiceInitialLength}d`;

			if (this.selectedRadioInvoice) {
				this.project.lastInvoiceFormat = this.defaultFormat;
			} else {
				const yearFormat = valueYearFormat.get(this.selectedRadioInvoiceYear);
				this.project.lastInvoiceFormat = this.invoiceInitialText.toUpperCase() + yearFormat + invoiceNumberFormat;
			}
			project.lastInvoiceFormat = this.project.lastInvoiceFormat;

			const poNumberFormat = `%0${this.poInitialLength}d`;

			if (this.selectedRadioPo) {
				this.project.lastPoFormat = this.defaultFormat;
			} else {
				const yearFormat = valueYearFormat.get(this.selectedRadioPoYear);
				this.project.lastPoFormat = this.poInitialText.toUpperCase() + yearFormat + poNumberFormat;
			}
			project.lastPoFormat = this.project.lastPoFormat;

			return project;
		},
		handleChangeOfferParameter() {
			this.getNewFormatValue(this.project);
		},
		handleChangeOrderParameter() {
			this.getNewFormatValue(this.project);
		},
		handleChangeInvoiceParameter() {
			this.getNewFormatValue(this.project);
		},
		handleChangePoParameter() {
			this.getNewFormatValue(this.project);
		},
		handleChangeOfferInitialLength() {
			this.getNewFormatValue(this.project);
		},
		handleChangeOrderInitialLength() {
			this.getNewFormatValue(this.project);
		},
		handleChangeInvoiceInitialLength() {
			this.getNewFormatValue(this.project);
		},
		handleChangePoInitialLength() {
			this.getNewFormatValue(this.project);
		},
		handleChangeOfferYear() {
			this.getNewFormatValue(this.project);
		},
		handleChangeOrderYear() {
			this.getNewFormatValue(this.project);
		},
		handleChangeInvoiceYear() {
			this.getNewFormatValue(this.project);
		},
		handleChangePoYear() {
			this.getNewFormatValue(this.project);
		},
		handleChangeOfferInitialText() {
			this.getNewFormatValue(this.project);
		},
		handleChangeOrderInitialText() {
			this.getNewFormatValue(this.project);
		},
		handleChangeInvoiceInitialText() {
			this.getNewFormatValue(this.project);
		},
		handleChangePoInitialText() {
			this.getNewFormatValue(this.project);
		},
		handleChangeOfferLastNumber() {
			if (this.lastNumberValidation(this.project.lastOfferNumber)) {
				this.getNewFormatValue(this.project);
			}
		},
		handleChangeOrderLastNumber() {
			if (this.lastNumberValidation(this.project.lastOrderNumber)) {
				this.getNewFormatValue(this.project);
			}
		},
		handleChangeInvoiceLastNumber() {
			if (this.lastNumberValidation(this.project.lastInvoiceNumber)) {
				this.getNewFormatValue(this.project);
			}
		},
		handleChangePoLastNumber() {
			if (this.lastNumberValidation(this.project.lastPoNumber)) {
				this.getNewFormatValue(this.project);
			}
		},
		async loadCcnForSolo() {
			try {
				const { data } = await apollo.mutate({
					mutation: gql`
						mutation {
							LoadCcnForSolo
						}
					`
				});
				let c = data.LoadCcnForSolo;

				this.$toast({
					title: this.FormMSG(252, 'Information'),
					variant: c ? 'success' : 'danger',
					message: c ? this.FormMSG(253, 'Loading CCN data was successfull') : this.FormMSG(254, 'Loading CCN data faileds')
				});
			} catch (e) {
				console.log(e);
			}
		},
		onAdminOwnerUnselected() {
			this.isSupplierUpdate = false;
			this.legalEntities = [];
		},
		handleControlOvertime(payload, type, num) {
			let typeStr = null;

			switch (type) {
				case 'daily':
					typeStr = '';
					break;
				case 'weekly':
					typeStr = 'Weekly';
					break;
			}

			if (num === 1) {
				if (!payload) {
					this.project[`use${typeStr}Ovt${num}`] = false;
					this.project[`use${typeStr}Ovt${num + 1}`] = false;
				}
			} else if (num === 2) {
				if (!payload) {
					this.project[`use${typeStr}Ovt${num}`] = false;
				} else {
					if (type === 'daily') {
						this.project.useOvertime = true;
					} else if (type === 'weekly') {
						this.project.useWeeklyOvt = true;
					}
				}
			} else if (num === 3) {
				if (payload) {
					if (type === 'daily') {
						this.project.useOvertime = true;
					} else if (type === 'weekly') {
						this.project.useWeeklyOvt = true;
					}
					this.project[`use${typeStr}Ovt${num - 2}`] = true;
				}
			}
		},
		handleControlEntryForCrew(payload, num) {
			if (num === 0) {
				this.project.useEntryForCrewExpenses = payload;
				this.project.useEntryForCrewPo = payload;
				this.project.useEntryForCrewTimesheet = payload;
			} else if (num > 0) {
				if (!this.project.useEntryForCrewExpenses && !this.project.useEntryForCrewPo && !this.project.useEntryForCrewTimesheet) {
					this.project.useEntryForCrew = false;
				} else {
					this.project.useEntryForCrew = true;
				}
			}
		},
		handleControlExpense(payload, num) {
			if (num === 0) {
				this.project.useExpenseTravel = payload;
				this.project.useExpenseTicket = payload;
				this.project.useExpenseInvoice = payload;
			} else if (num > 0) {
				if (!this.project.useExpenseTravel && !this.project.useExpenseTicket && !this.project.useExpenseInvoice) {
					this.project.useExpense = false;
				} else {
					this.project.useExpense = true;
				}
			}
		}
	}
};
</script>
