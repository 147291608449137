<template>
	<b-modal
		v-model="isOpen"
		:title="FormMSG(25, 'Public holidays')"
		size="xl"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		cancel-variant="outline-primary"
		ok-variant="primary"
		:cancel-title="FormMSG(35, 'Close')"
		:ok-title="labelOkTitle"
		@show="reloadData"
		@ok.prevent="handleClickOk"
		@hidden="emitEventClose"
	>
		<b-row class="mb-3">
			<b-col class="text-center">
				<b-button-group>
					<b-button :variant="`${manageCalendar ? 'outline-' : ''}primary`" @click="toggleManageCalendar">
						{{ FormMSG(37, 'Public holidays') }}
					</b-button>
					<b-button :variant="`${!manageCalendar ? 'outline-' : ''}primary`" @click="toggleManageCalendar">
						{{ FormMSG(38, 'Manage calendars') }}
					</b-button>
				</b-button-group>
			</b-col>
		</b-row>
		<div v-if="!manageCalendar">
			<b-row>
				<b-col>
					<b-table
						ref="table-holidays"
						selected-variant="primary"
						:hover="true"
						responsive="sm"
						selectable
						select-mode="single"
						:items="holidays"
						style="text-align: center"
						:fields="holidaysField"
						head-variant="dark"
						bordered
						small
						show-empty
						:empty-text="FormMSG(125, 'No holidays found')"
					>
						<template #cell(date)="data">
							<div>
								<div v-if="!data.item.forEdit">
									{{ data.item.date | formatDate('DD/MM/YYYY') }}
								</div>
								<div v-else>
									<v-date-picker v-model="data.item.date" :locale="lang" :masks="masks">
										<template v-slot="{ inputValue, togglePopover }">
											<b-input-group>
												<b-form-input :value="inputValue" readonly />
												<b-input-group-append @click="togglePopover">
													<b-input-group-text class="cursor-pointer">
														<component :is="getLucideIcon('Calendar')" color="#06263E" :size="16" />
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</template>
									</v-date-picker>
								</div>
							</div>
						</template>
						<template #cell(name)="data">
							<div>
								<div v-if="!data.item.forEdit">
									{{ data.item.name }}
								</div>
								<div v-else>
									<b-form-input v-model="data.item.name" :placeholder="FormMSG(214, 'Name')" />
								</div>
							</div>
						</template>
						<template #cell(shortName)="data">
							<col style="width: 100px" />
							<div>
								<div v-if="!data.item.forEdit">
									{{ data.item.shortName }}
								</div>
								<div v-else>
									<b-form-input v-model="data.item.shortName" :placeholder="FormMSG(215, 'Short name')" />
								</div>
							</div>
						</template>
						<template #cell(rate)="data">
							<col style="width: 100px" />
							<div>
								<div v-if="!data.item.forEdit">
									{{ data.item.rate }}
								</div>
								<div v-else>
									<b-input-group>
										<b-form-input v-model="data.item.rate" type="number" step="0.01" min="0" placeholder="0" />
										<b-input-group-append>
											<b-input-group-text>
												<component :is="getLucideIcon('Percent')" color="#06263E" :size="16" class="icon" />
											</b-input-group-text>
										</b-input-group-append>
									</b-input-group>
								</div>
							</div>
						</template>
						<template #cell(paid)="data">
							<div>
								<div v-if="!data.item.forEdit">
									{{ data.item.paidStr !== '' ? data.item.paidStr : '00:00' }}
								</div>
								<div v-else>
									<b-input-group>
										<b-form-input v-model="data.item.paidStr" type="time" />
										<b-input-group-append>
											<b-input-group-text>
												<component :is="getLucideIcon('Clock')" color="#06263E" :size="16" />
											</b-input-group-text>
										</b-input-group-append>
									</b-input-group>
								</div>
							</div>
						</template>
						<template #cell(calNumber)="data">
							<div>
								<div v-if="!data.item.forEdit">
									{{ data.item.publicHolidayCalendar.name }}
								</div>
								<div v-else>
									<v-select
										v-model="data.item.calNumber"
										:options="calendars"
										label="name"
										:reduce="(option) => option.calNumber"
										:clearable="false"
										:placeholder="FormMSG(219, 'Select a calendar')"
									/>
								</div>
							</div>
						</template>
						<template #cell(color)="data">
							<div>
								<div v-if="!data.item.forEdit">
									<b-img v-bind="{ blank: true, blankColor: data.item.color, width: 20, height: 20, class: 'm1' }" rounded="circle" />
								</div>
								<div v-else>
									<verte v-model="data.item.color" picker="square" model="hex" menuPosition="right" />
								</div>
							</div>
						</template>
						<template #cell(edit)="data">
							<div v-if="!data.item.forEdit">
								<button class="btn-transparent" @click="editHoliday(data.item)">
									<component :is="getLucideIcon('Edit')" color="#225CBD" />
								</button>
							</div>
							<div v-else>
								<button class="btn-transparent" @click="updateHoliday(data.item)">
									<component :is="getLucideIcon('CheckSquare')" color="#3C7442" />
								</button>
							</div>
						</template>
						<template #cell(remove)="data">
							<div>
								<button class="btn-transparent text-danger" @click="delHoliday(data.item)">
									<component :is="getLucideIcon('Trash2')" :size="20" :stroke-width="2" />
								</button>
							</div>
						</template>
					</b-table>
				</b-col>
			</b-row>
		</div>
		<div v-else>
			<b-row>
				<b-col>
					<b-table
						ref="table-calendars-holidays"
						selected-variant="primary"
						:hover="true"
						responsive="sm"
						selectable
						select-mode="single"
						:items="calendars"
						style="text-align: center"
						:fields="calendarsField"
						head-variant="dark"
						bordered
						small
						show-empty
						:empty-text="FormMSG(126, 'No calendars found')"
					>
						<template #cell(calNumber)="data">
							<div>
								{{ data.item.calNumber }}
							</div>
						</template>
						<template #cell(name)="data">
							<div v-if="!data.item.forEdit">
								{{ data.item.name }}
							</div>
							<div v-else>
								<b-form-input v-model="data.item.name" />
							</div>
						</template>
						<template #cell(edit)="data">
							<div v-if="!data.item.forEdit">
								<button class="btn-transparent" @click="editCalendar(data.item)">
									<component :is="getLucideIcon('Edit')" color="#225CBD" />
								</button>
							</div>
							<div v-else>
								<button class="btn-transparent" @click="updateCalendar(data.item)">
									<component :is="getLucideIcon('CheckSquare')" color="#3C7442" />
								</button>
							</div>
						</template>
						<template #cell(remove)="data">
							<div v-if="data.item.calNumber > 0">
								<button class="btn-transparent text-danger" @click="delCalendar(data.item)">
									<component :is="getLucideIcon('Trash2')" :size="20" :stroke-width="2" />
								</button>
							</div>
						</template>
					</b-table>
				</b-col>
			</b-row>
		</div>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';

import {
	newPublicHolidayCalendar,
	updatePublicHolidayCalendar,
	delPublicHolidayCalendar,
	getProjectPublicHolidayCalendar,
	getPublicHolidays,
	delPublicHoliday,
	newPublicHoliday,
	updatePublicHoliday
} from '@/cruds/holidays.crud';

import InlineButton from '@/components/PayrollCode/InlineButton';
import InlineInput from '@/components/PayrollCode/InlineInput';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import verte from 'verte';
import 'verte/dist/verte.css';
import moment from 'moment';

export default {
	name: 'HolidaysModal',

	props: {
		open: { type: Boolean, default: false }
	},

	components: {
		InlineButton,
		InlineInput,
		'v-date-picker': DatePicker,
		verte
	},

	mixins: [languageMessages, GlobalMixin],

	data() {
		return {
			manageCalendar: false,
			holidays: [],
			calendars: [],
			masks: {
				input: 'DD/MM/YYYY'
			},

			lastIndexForCreate: 'c_1'
		};
	},

	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		labelOkTitle() {
			if (!this.manageCalendar) {
				return this.FormMSG(36, 'Add new day');
			} else {
				return this.FormMSG(84, 'Add new calendar');
			}
		},
		holidaysField() {
			return [
				{
					key: 'date',
					label: this.FormMSG(51, 'Date'),
					sortable: true,
					class: 'w-150-px'
				},
				{
					key: 'name',
					label: this.FormMSG(52, 'Name'),
					sortable: true,
					class: 'w-150-px'
				},
				{
					key: 'shortName',
					label: this.FormMSG(53, 'Short name'),
					sortable: true,
					class: 'w-100-px'
				},
				{
					key: 'rate',
					label: this.FormMSG(54, 'Rate'),
					sortable: true,
					class: 'w-125-px'
				},
				{
					key: 'paid',
					label: this.FormMSG(55, 'Paid if not worked'),
					sortable: true,
					class: 'w-150-px'
				},
				{
					key: 'calNumber',
					label: this.FormMSG(56, 'Calendar'),
					sortable: true
				},
				{
					key: 'color',
					label: this.FormMSG(57, 'Color'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'edit',
					label: this.FormMSG(58, 'Edit'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'remove',
					label: this.FormMSG(59, 'Remove'),
					sortable: true,
					class: 'text-center'
				}
			];
		},
		calendarsField() {
			return [
				{
					key: 'calNumber',
					label: this.FormMSG(65, 'Number'),
					sortable: true,
					class: 'w-300-px'
				},
				{
					key: 'name',
					label: this.FormMSG(52, 'Name'),
					sortable: true,
					class: 'w-300-px'
				},
				{
					key: 'edit',
					label: this.FormMSG(58, 'Edit'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'remove',
					label: this.FormMSG(59, 'Remove'),
					sortable: true,
					class: 'text-center'
				}
			];
		}
	},

	methods: {
		async reloadData() {
			await this.getCalendars();
			await this.getHolidays();
		},
		sortHolidays() {
			this.holidays = this.holidays.sort(function compare(a, b) {
				if (new Date(a.date).getTime() < new Date(b.date).getTime()) return 1;
				if (new Date(a.date).getTime() > new Date(b.date).getTime()) return -1;
				return 0;
			});
		},
		convertHourMinuteToInt(item) {
			let result = 0;
			if (
				item.paidStr.split(':').length === 2 &&
				_.isNaN(parseInt(item.paidStr.split(':')[0], 10)) === false &&
				_.isNaN(parseInt(item.paidStr.split(':')[1], 10)) === false
			) {
				result = parseInt(item.paidStr.split(':')[0], 10) * 60 + parseInt(item.paidStr.split(':')[1], 10);
			}

			return result;
		},
		async updateHoliday(item) {
			if (this.checkIfDateIsExist(item) === false) {
				let holidays = _.cloneDeep(this.holidays);

				const paid = this.convertHourMinuteToInt(item);
				const inputHoliday = {
					calNumber: parseInt(item.calNumber, 10),
					date: moment(new Date(item.date)).format('YYYY-MM-DD') + 'T00:00:00Z',
					name: item.name,
					shortName: item.shortName,
					rate: parseFloat(item.rate),
					paid: paid,
					color: item.color
				};

				if (item.id.indexOf('c_') > -1) {
					await newPublicHoliday(inputHoliday)
						.then((result) => {
							const findIndex = holidays.findIndex((holiday) => holiday.id === item.id);
							holidays[findIndex] = {
								...result,
								date: new Date(result.date),
								forEdit: false
							};

							item.forEdit = false;
							this.holidays = holidays;

							this.createToastForMobile(this.FormMSG(145, 'Success'), this.FormMSG(153, 'Holiday updated successfully!'));
							this.sortHolidays();
						})
						.catch((error) => {
							console.log(error);
						});
				} else {
					await updatePublicHoliday(item.id, inputHoliday)
						.then((result) => {
							const findIndex = holidays.findIndex((holiday) => holiday.id === result.id);
							holidays[findIndex] = {
								...result,
								date: new Date(result.date),
								forEdit: false
							};

							item.forEdit = false;
							this.holidays = holidays;

							this.createToastForMobile(this.FormMSG(145, 'Success'), this.FormMSG(153, 'Holiday updated successfully!'));
							this.sortHolidays();
						})
						.catch((error) => {
							console.log(error);
						});
				}
			} else {
				this.createToastForMobile(this.FormMSG(547, 'Error'), this.FormMSG(548, 'The date already exist'), '', 'danger');
			}
		},
		checkIfDateIsExist(item) {
			let result = false;

			for (let i = 0; i < this.holidays.length; i++) {
				const element = this.holidays[i];
				if (
					moment(new Date(element.date)).format('YYYY-MM-DD') === moment(new Date(item.date)).format('YYYY-MM-DD') &&
					parseInt(element.calNumber) === parseInt(item.calNumber) &&
					element.id.indexOf('c_') === -1 &&
					JSON.stringify(element) !== JSON.stringify(item)
				) {
					result = true;
					break;
				}
			}

			return result;
		},
		async updateCalendar(item) {
			let calendars = _.cloneDeep(this.calendars);
			// this.calendars = [];
			await updatePublicHolidayCalendar(item.calNumber, {
				name: item.name
			})
				.then((result) => {
					const findIndex = calendars.findIndex((calendar) => calendar.id === result.id);
					calendars[findIndex] = {
						...result,
						forEdit: false
					};

					item.forEdit = false;
					this.calendars = calendars;

					this.createToastForMobile(this.FormMSG(145, 'Success'), this.FormMSG(514, 'Calendar updated successfully!'));
				})
				.catch((error) => {
					console.log(error);
				});
		},
		editHoliday(item) {
			item.forEdit = true;
		},
		editCalendar(item) {
			item.forEdit = true;
		},
		async handleClickOk() {
			if (!this.manageCalendar) {
				await this.newHoliday();
			} else {
				await this.newCalendar();
			}
		},
		async newHoliday() {
			this.holidays.unshift({
				id: this.lastIndexForCreate,
				calNumber: 0,
				date: new Date(moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00Z'),
				name: this.FormMSG(85, 'New holiday'),
				shortName: '',
				rate: 100,
				paid: 0,
				color: '#00a09c',
				paidStr: '',
				forEdit: true
			});

			const lastIndexForCreateSplited = this.lastIndexForCreate.split('_')[1];
			const newIndex = parseInt(lastIndexForCreateSplited, 10) + 1;
			this.lastIndexForCreate = 'c_' + newIndex;
		},
		async newCalendar() {
			await newPublicHolidayCalendar({
				name: this.FormMSG(450, 'NEW CALENDAR')
			})
				.then((result) => {
					this.calendars.push({
						...result,
						forEdit: true
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		async delHoliday(item) {
			let action = null;
			if (item.id.indexOf('c_') > -1) {
				action = () => {
					this.holidays = this.holidays.filter((option) => option.id !== item.id);

					this.createToastForMobile(this.FormMSG(145, 'Success'), this.FormMSG(149, 'Holiday deleted successfully!'));
					this.sortHolidays();
				};
			} else {
				action = async () => {
					await delPublicHoliday(item.id);

					this.holidays = this.holidays.filter((holiday) => holiday.id !== item.id);

					this.createToastForMobile(this.FormMSG(145, 'Success'), this.FormMSG(149, 'Holiday deleted successfully!'));
					this.sortHolidays();
				};
			}

			this.confirmModal(action, this.FormMSG(87, 'Are you sure ?'));
		},
		async delCalendar(item) {
			const action = async () => {
				await delPublicHolidayCalendar(item.calNumber);

				this.calendars = this.calendars.filter((calendar) => calendar.calNumber !== item.calNumber);
				this.holidays = this.holidays.filter((holiday) => holiday.calNumber !== item.calNumber);

				this.createToastForMobile(this.FormMSG(145, 'Success'), this.FormMSG(158, 'Calendar deleted successfully!'));
			};

			this.confirmModal(action, this.FormMSG(87, 'Are you sure ?'));
		},
		async getHolidays() {
			this.holidays = await getPublicHolidays();
			this.holidays = this.holidays.map((option) => ({
				...option,
				date: new Date(option.date),
				forEdit: false
			}));
		},
		async getCalendars() {
			this.calendars = await getProjectPublicHolidayCalendar();
			this.calendars = this.calendars.map((option) => ({
				...option,
				forEdit: false
			}));
		},
		toggleManageCalendar() {
			this.manageCalendar = !this.manageCalendar;
		},

		emitEventClose() {
			this.$emit('holidays-modal:close');
		}
	}
};
</script>

<style scoped></style>
